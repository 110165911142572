import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { Card } from 'primereact/card'
import { fetchUserPublishedListForWeek, PublishedSchedule } from '../../helpers/workTimeScheduleHelper'
import { getStaffId } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { NO_SHIFT_ASSIGNED } from '../../constants/strings'
import { routes } from '../../constants/routes'
import { Skeleton } from 'primereact/skeleton'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { USER_PUBLISHED_LIST_FOR_WEEK } from '../../constants/queryKeys'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import StaffScheduleMobileView from '../staff_schedule/StaffScheduleMobileView'
import useQueryHook from '../../hooks/useQueryHook'

function MyScheduleCard() {
    const staffId = getStaffId()
    const navigate = useNavigate()
    const [todaySchedule, setTodaySchedule] = useState<PublishedSchedule[]>([]);

    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [scheduleData, setScheduleData] = useState<PublishedSchedule[]>([]);

    const getTwoWeeksRange = (selectedDate: dayjs.Dayjs) => {
        const startOfWeek = selectedDate.isoWeekday(1); // Start of this week (Monday)
        const endOfNextWeek = selectedDate.add(1, 'week').isoWeekday(7); // End of next week (Sunday)

        return {
            startDate: startOfWeek.format('YYYY-MM-DD'),
            endDate: endOfNextWeek.format('YYYY-MM-DD')
        };
    };
    const dateRange = getTwoWeeksRange(selectedWeek); // Calculate the date range for the selected week
    const queryClient = useQueryClient()


    const onSuccess = (res: AxiosResponse<any, any>) => {
        const publishedSchedule: PublishedSchedule[] = res.data.data;
        setScheduleData(publishedSchedule);
    };

    const scheduleQuery = useQueryHook(USER_PUBLISHED_LIST_FOR_WEEK, () => fetchUserPublishedListForWeek({ tenantStaffId: staffId, dateRange: dateRange }), onSuccess);

    useEffect(() => {
        queryClient.refetchQueries([USER_PUBLISHED_LIST_FOR_WEEK]);
    }, [selectedWeek]);

    // Filter todaySchedule whenever scheduleData updates
    useEffect(() => {
        if (!scheduleData.length) return
        const today = dayjs().format('YYYY-MM-DD');

        const filteredData = scheduleData.filter(item => item.scheduleDate === today);
        setTodaySchedule(filteredData);
    }, [scheduleData]);

    const cardBodyTemplate = () => {
        const cardBody = todaySchedule?.map((item) => {
            const { assignedShifts } = item
            const { shiftName, startTime, endTime, role, isBd, isClose } = assignedShifts
            return (
                <div key={uuidv4()} className='staff-schedule-dashboard-card'>
                    <div className="staff-schedule-dashboard" >
                        {isClose ? <div className="time">{`${startTime} - "Close"`}</div> : ""}
                        {isBd ? <div className="time">{`${startTime} - "V"`}</div> : ""}
                        {!isBd && !isClose ? <div className="time">{`${startTime} - ${endTime}`}</div> : ''}
                        <div className="role">{role}</div>
                    </div>
                </div >
            )
        })

        // If no valid entries, return "No Data" message
        if (cardBody?.length === 0) {
            return (
                <div className="staff-schedule-card-no-reservation">
                    <div className="staff-schedule-dashboard"><JTranslation typeCase="none" text={NO_SHIFT_ASSIGNED} /></div>
                </div>
            );
        }

        // Return valid entries wrapped in a React Fragment
        return <>{cardBody}</>;
    }

    return (
        <Card className="dashboard-schedule-card card h-100 custom-shadow border-radius-05 border-0" style={{ maxHeight: '550px', overflowY: "auto" }}
            title={
                <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <JTranslation typeCase="pascal" text={"My Schedule"} />
                    </div>
                    <button className="btn btn-custom-primary dashboard-btn" onClick={() => navigate(routes.staff_schedule)}><JTranslation typeCase="pascal" text={'View All'} /></button>
                </div>
            }
        >
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="h-100 ">
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <Card className='data-view-card h-100 shadow-none'>
                                <p className='fw-bold mb-1 success-text'><JTranslation typeCase="pascal" text={"Today's Schedule"} /></p>
                                <p>{dayjs().format('ddd, D MMM YYYY')}</p>
                                <div className="dashboard-schedule-scroll">
                                    {scheduleQuery.isFetching ?
                                        <Skeleton className='mb-1' height='4rem'></Skeleton>
                                        :
                                        cardBodyTemplate()
                                    }
                                </div>
                            </Card>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <StaffScheduleMobileView selectedWeek={selectedWeek} scheduleData={scheduleData} isFetching={scheduleQuery.isFetching} parent='dashboard' />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default MyScheduleCard