import React, { useEffect, useState } from "react";
import withSidebar from "../../hoc/withSidebar";
import { JTranslation } from "../../helpers/jTranslate";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "./help.scss";
import { HELP_LIST } from "../../constants/queryKeys";
import useQueryHook from "../../hooks/useQueryHook";
import { AxiosResponse } from "axios";
import { getHelpList, HelpItem, unescapeMarkdown } from "../../helpers/helpHelper";
import MediaThemeSutro from "player.style/sutro/react";
import Loader from "../../components/loader/Loader";

function Help() {
  const [helpDataList, setHelpDataList] = useState<HelpItem[]>();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHelpData, setFilteredHelpData] = useState<HelpItem[] | undefined>([]);

  const onHelpListFetchSuccess = (res: AxiosResponse<any, any>) => {
    setHelpDataList(res.data.data.data);
    setInitialLoad(false);
  };

  const helpData = useQueryHook(
    HELP_LIST,
    () => getHelpList("", 100, ""),
    onHelpListFetchSuccess
  );

  useEffect(() => {
    const filtered = helpDataList?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        unescapeMarkdown(item.content).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredHelpData(filtered);
  }, [searchTerm, helpDataList]);

  // Highlight the search term in the text
  const highlightSearchTerm = (text:string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(regex, "<span class='help-search-highlight'>$1</span>");
  };

  const VideoPlayer: React.FC<{ src: string }> = ({ src }) => {
    const isYouTube = src.includes("youtube.com") || src.includes("youtu.be");
    const isVimeo = src.includes("vimeo.com");

    if (isYouTube || isVimeo) {
      return (
        <div className="video-embed">
          <iframe
            src={
              isYouTube
                ? `https://www.youtube.com/embed/${extractYouTubeId(src)}`
                : `https://player.vimeo.com/video/${extractVimeoId(src)}`
            }
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    }

    return (
      <MediaThemeSutro
        style={{ "--media-accent-color": "#feba01" } as React.CSSProperties}
        className="tutorial-video"
      >
        <video slot="media" src={src}  crossOrigin="anonymous"></video>
      </MediaThemeSutro>
    );
  };

  const extractYouTubeId = (url:any) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match ? match[1] : null;
  };

  const extractVimeoId = (url:any) => {
    const match = url.match(/(?:vimeo\.com\/(?:.*#|.*\/videos\/)?)([0-9]+)/);
    return match ? match[1] : null;
  };

  return (
    <>
      <div className="page-title">
        <h5 className="d-flex">
          <JTranslation typeCase="pascal" text={"Help"} />
        </h5>
      </div>

      <div className="col-md-12 col-lg-12 mb-3 h-100">
        <div className="card h-100 overflow-hidden">
          <div className="card-body overflow-hidden p-0">
            <div className=" h-100 pb-5 overflow-auto">
              <div className="col-12 p-0">
                <div className="header-section help-banner">
                  <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                    <h5 className="mb-2">
                      <JTranslation typeCase="none" text={"How can we help you"} />
                    </h5>
                    <div className="p-input-icon-left w-100">
                      <i className="pi pi-search" />
                      <InputText placeholder="Search" className="w-100" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                  </div>
                </div>

                {helpData.isLoading || initialLoad ? (
                  <Loader />
                ) : (
                  <div className="help-accordion-section">
                    <Accordion>
                      {filteredHelpData?.map((helpItem, index) => (
                        <AccordionTab key={index} header={<span dangerouslySetInnerHTML={{ __html: highlightSearchTerm(helpItem.title) }} />}>
                          <div>
                            <div style={{ marginBottom: "1rem" }}>
                              <Markdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                  a: ({ href, children }) => {
                                    if (
                                      href &&
                                      (/\.(mp4|webm|ogg|avi|mov|flv)$/i.test(href) ||
                                        href.includes("videos-bucket") ||
                                        href.includes("youtube.com") ||
                                        href.includes("youtu.be") ||
                                        href.includes("vimeo.com"))
                                    ) {
                                      return <VideoPlayer src={href} />;
                                    }
                                    return (
                                      <a href={href} target="_blank" rel="noopener noreferrer">
                                        {children}
                                      </a>
                                    );
                                  },
																	img: ({ src, alt }) => {
																		return <img src={src} alt={alt} className="help-img"  />;
																	},
																	ol: ({children}) => {
																		return <ol className="help-list-content">{children}</ol>
																	},
																	ul: ({children}) => {
																		return <ul className="help-list-content">{children}</ul>
																	}
                                }}
                              >
                                {unescapeMarkdown(highlightSearchTerm(helpItem.content))}
                              </Markdown>
                            </div>
                          </div>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withSidebar(Help);
