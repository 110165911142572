export const routes = {
    addons: "/addons",
    branding_config: "/branding-config",
    custom_menu_list: "/custom-menu-list",
    dashboard: "/dashboard",
    events_in_detail: "/events",
    other: "/other",
    events_only: "/events-list",
    file_management: "/file-management",
    file_type_verification: "/file-type-verification",
    forgot: "/forgot-password",
    game_management: "/manage-game",
    guest_game: "/play-game",
    guest_info: "/guest-info",
    holidays: "/holidays",
    item_view_special_menu: "/item-view-special-menu",
    login: "/login",
    magic_link_page: "/ml",
    manage_addons_groups: "/addons/addon-groups",
    manage_addons: "/addons",
    manage_coupon: "/manage-voucher",
    manage_ingredients: "/ingredients",
    manage_users: "/manage-users",
    menu_item_view: "/item-view",
    menu_view: "/menu",
    menu: "/menu-view",
    news_and_events_management: "/news-events-management",
    news_and_events: "/news-and-events",
    news_in_detail: "/news",
    news_only: "/news-list",
    redirect_page: "/rp/:shortCode",
    reset: "/reset-password",
    set_password: "/set-new-password",
    shift_config: "/shift-config",
    special_menu: "/special-menu",
    survey_create: "/survey/create",
    survey_guest: "/guest-survey",
    survey_report: "/survey-report",
    survey: "/survey",
    time_off_requests: "/time-off-requests",
    time_off: "/time-off",
    ui_tests: "/ui_tests",
    user_roles: "/user-roles",
    welcome_page: "/welcome",
    welcome_screen_config: "/welcome-screen-config",
    wine_history: "/view-history",
    availability: "/availability",
    availability_list: "/availability-list",
    view_availability: "/view-availability",
    manage_work_schedule: "/manage-work-schedule",
    profile: "/profile",
    settings: "/settings",
    staff_schedule: "/staff-schedule-view",
    about_us: "/about-us",
    my_notifications: "/my-notifications",
    permission_groups: "/permission-groups",
    roles: "/roles",
    print_preview: "/print-preview",
    shift_swap: "/shift-swap",
    swap_requests: "/shift-swap/requests",
    help: "/help",
}

// Kindly update the guestOnlyRoutes array whenever you introduce a new guest-specific route
export const guestOnlyRoutes = [
    '/guest/',
    routes.events_in_detail,
    routes.events_only,
    routes.guest_game,
    routes.item_view_special_menu,
    routes.menu_item_view,
    routes.menu_view,
    routes.news_and_events,
    routes.news_in_detail,
    routes.news_only,
    routes.survey_guest,
    routes.welcome_page,
    routes.wine_history,
  ]