import { useState, useContext, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { CommonCtx } from '../../context/CommonCtxProvider'
import {
	HOLIDAY_LIST,
	HOLIDAY_MANAGEMENT,
	HELP_HOLIDAY_PAGE,
	ADD_HOLIDAY,
	DELETE,
	EDIT,
	DELETE_HOLIDAY,
	DELETE_HOLIDAY_TITLE,
	HOLIDAYS,
	HELP_HOLIDAY_LIST,
	BUSINESS_CLOSE,
} from '../../constants/strings'
import { Dropdown } from 'primereact/dropdown'
import {
	getAllHolidaysList,
	generateYearOptions,
	currentYear,
	initialValuesForConfirmPopup,
	initialValuesForHoliday,
	getHolidayDataById,
	holidayDelete,
} from '../../helpers/holidayHelper'
import { HOLIDAYS_LIST } from '../../constants/queryKeys'
import { checkPermission, dateFormatter, toPascalCase, calculateDays } from '../../helpers/utils'
import {
	toastMessageInitialData,
	PageMode,
	PermissionKeys,
	SubPermissionKeys,
	AlertVariant,
	MOBILE_VIEW_BREAKPOINT,
} from '../../constants/constants'
import { ToastMessageProps, HolidayData, ConfirmPopupType, AddHolidayResponse, Id } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import HolidayImage from '../../assets/images/holiday_bg.svg'
import HolidaySideBar from '../../components/holiday_list/HolidaySideBar'
import Loader from '../../components/loader/Loader'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
import dayjs from 'dayjs'
import { Badge } from 'primereact/badge'
import { FaEdit } from 'react-icons/fa'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import useMutationHook from '../../hooks/useMutationHook'
import CommonModal from '../../components/common_modal/CommonModal'
import { useWindowSize } from 'react-use'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import MlxTooltip from '../../components/common/MlxTooltip'
import MlxPopover from '../../components/common/MlxPopover'

function HolidayConfig() {
	const queryClient = useQueryClient()
	const timeOffMutation = useMutationHook(queryClient, true)
	const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)
	const { setHolidayData } = useContext(ScheduleManagementCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [showWarning, setWarning] = useState(false)
	const [timeOffRequestList, setTimeOffRequestList] = useState<HolidayData[]>([])
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [year, setYear] = useState({ label: String(currentYear), value: String(currentYear) })
	const [holidayInfo, setHolidayInfo] = useState<ConfirmPopupType>(initialValuesForConfirmPopup)
	const yearOptions = generateYearOptions()

	const [translationText, setTranslatedText] = useState<{
		edit: string,
		delete: string,
	}>({
		edit: EDIT,
		delete: DELETE,
	});

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
				])

				setTranslatedText({
					edit: translations[0] ?? EDIT,
					delete: translations[1] ?? DELETE,
				})
			} catch {
				setTranslatedText({
					edit: EDIT,
					delete: DELETE,
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	const parsedHolidayType = (holidayType: string) => {
		switch (holidayType) {
			case '':
				return '';
			case 'public':
				return 'Federal Holiday';
			case 'religious':
				return 'Religious Holiday';
			case 'cultural':
				return 'Cultural Holiday';
			case 'state':
				return 'State Holiday';
			case 'optional_observances':
				return 'Restaurant Holiday';
			default:
				return '';
		}
	}

	useEffect(() => {
		// Reset holiday query and to show loader and data
		queryClient.removeQueries(HOLIDAYS_LIST)
		queryClient.fetchQuery(HOLIDAYS_LIST, () => getAllHolidaysList(Number(year.value)))
	}, [year, queryClient]) // eslint-disable-line

	useEffect(() => {
		if (!showSideBar) {
			setHolidayInfo(initialValuesForConfirmPopup)
			setHolidayData(initialValuesForHoliday)
			setMode(PageMode.ADD)
		}
	}, [showSideBar]) // eslint-disable-line

	useEffect(() => {
		if (holidayInfo.action === 'EDITED') getFullRequestDataById()
	}, [holidayInfo]) // eslint-disable-line

	const parseDuration = (timeOff: HolidayData) => {
		const { durationType, startDate, endDate, timeLabel } = timeOff

		if (durationType === 'half-day') {
			return `Half day (${timeLabel})`
		} else {
			return calculateDays({
				fromDate: startDate,
				toDate: endDate,
			})
		}
	}

	const durationTemplate = (timeOff: HolidayData) => {
		const duration = parseDuration(timeOff)
		return <span className="holiday-name">{duration}</span>
	}

	const onFullDataSuccess = (res: HolidayData) => {
		const { durationType, endDate, startDate, title, isPaid, description, timeLabel, holidayType, isOpenForBusiness } = res

		// set data to timeOff request form
		setHolidayData({
			id: holidayInfo.requestId,
			title,
			durationType,
			startDate,
			endDate,
			description,
			isPaid,
			isOpenForBusiness,
			timeLabel,
			holidayType,
			duration: calculateDays({
				fromDate: startDate,
				toDate: endDate,
			}),
		})
		// open sidebar
		setShowSideBar(true)
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const onDeleteSuccess = (res: AddHolidayResponse) => {
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
		queryClient.refetchQueries([HOLIDAYS_LIST])
	}

	const getFullRequestDataById = () => {
		const params: Id = { id: holidayInfo.requestId }
		getHolidayDataById(timeOffMutation, params, onFullDataSuccess, onError)
	}

	const deleteHolidayById = () => {
		const params: Id = { id: holidayInfo.requestId }
		holidayDelete(timeOffMutation, params, onDeleteSuccess, onError)
	}

	const onYearChange = (e: { value: any }) => {
		setYear({ label: e.value, value: e.value })
	}

	// users list fetch success
	const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const holidayList = res.data.data.lists as HolidayData[]
		const sortedHolidayList = holidayList
			.slice()
			.sort((a, b) => (dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1))
		setTimeOffRequestList(sortedHolidayList)
	}

	// fetch users list
	const holidayData = useQueryHook(HOLIDAYS_LIST, () => getAllHolidaysList(), onUserListFetchSuccess)

	if (holidayData.isLoading) {
		return <Loader />
	}

	const actionTemplate = (holiday: HolidayData) => {
		const { id } = holiday

		return (
			<>
				<div className="rounded-background">
					<MlxTooltip target='.edit'>
						<FaEdit
							data-pr-tooltip={translationText.edit}
							data-pr-position="bottom"
							className={
								'edit ' +
								(!checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.EDIT) &&
									' disabled')
							}
							onClick={() => {
								if (checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.EDIT)) {
									setHolidayInfo({ buttonName: id, visible: false, requestId: id, action: 'EDITED' })
									setMode(PageMode.EDIT)
								}
							}}
						/>
					</MlxTooltip>
				</div>

				<div className="rounded-background">
					<MlxTooltip target='.delete'>
						<i
							className={
								'ri-delete-bin-line delete ' +
								(!checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.DELETE) &&
									' disabled')
							}
							data-pr-tooltip={translationText.delete}
							data-pr-position='bottom'
							onClick={(e) => {
								e.stopPropagation()
								// open reject popup
								if (checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.DELETE)) {
									setHolidayInfo({ buttonName: id, visible: false, requestId: id, action: 'DELETE' })
									setWarning(true)
								}
							}}
						></i>
					</MlxTooltip>
				</div>
			</>
		)
	}

	const customDate = (holiday: HolidayData) => {
		if (holiday.startDate !== holiday.endDate) {
			return (
				<>
					{/* <p className="m-0">From: {dateFormatter({ date: holiday.startDate,format: 'MMM D, YYYY - dddd' })}</p>
					<p className="m-0">To: {dateFormatter({ date: holiday.endDate,format: 'MMM D, YYYY - dddd' })}</p> */}
					<p className="m-0">{dateFormatter({ date: holiday.startDate, format: 'MMM D, YYYY - ddd' })} To {dateFormatter({ date: holiday.endDate, format: 'MMM D, YYYY - ddd' })}</p>
					<p className="m-0"> </p>
				</>
			)
		}
		return <>{
			dateFormatter({
				date: holiday.startDate,
				format: 'MMM D, YYYY - ddd',
			})
		}</>
	}

	return (
		<>
			{/* Delete modal */}
			<CommonModal
				show={showWarning}
				modalContent={DELETE_HOLIDAY}
				title={DELETE_HOLIDAY_TITLE}
				modalSize="md"
				disableProceed={holidayInfo.action !== 'DELETE'}
				onHide={() => setWarning(false)}
				callback={() => {
					deleteHolidayById()
					setWarning(false)
				}}
				hideCloseButton
			/>

			{/* toaster component */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
			/>

			{/* side bar component  */}
			<HolidaySideBar
				pageMode={mode}
				setShowSideBar={setShowSideBar}
				setToastMessage={setToastMessage}
				year={year.value}
			/>

			<div className="page-title">
				<h5 className="d-flex">
					{checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.ADD) ||
						checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.EDIT) ||
						checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.DELETE)
						? <JTranslation typeCase="pascal" text={HOLIDAY_MANAGEMENT} />
						: <JTranslation typeCase="pascal" text={HOLIDAYS} />
					}
					<MlxPopover data={
						{
							title: checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.ADD) ||
								checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.EDIT) ||
								checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.DELETE)
								? HOLIDAY_MANAGEMENT
								: HOLIDAYS,
							body: checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.ADD) ||
								checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.EDIT) ||
								checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.DELETE)
								? HELP_HOLIDAY_PAGE
								: HELP_HOLIDAY_LIST
						}
					} />
				</h5>
			</div>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="card h-100 ">
					{(checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.ADD) ||
						checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.EDIT) ||
						checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY, SubPermissionKeys.DELETE)) && (
							<div className="card-header flex-grow-0">
								<div className="d-flex  align-items-center">
									<div className="flex-grow-1">
										<h4 className="mb-0 title"><JTranslation typeCase="pascal" text={HOLIDAY_LIST} /></h4>
									</div>
									<div className="flex-grow-0 me-2 ms-1">
										<button
											className={
												'btn btn-custom-primary d-flex  icon-only-btn ' +
												(!checkPermission(
													queryClient,
													PermissionKeys.MANAGE_HOLIDAY,
													SubPermissionKeys.ADD
												) && ' disabled')
											}
											type="button"
											onClick={() => {
												if (
													checkPermission(
														queryClient,
														PermissionKeys.MANAGE_HOLIDAY,
														SubPermissionKeys.ADD
													)
												) {
													setShowSideBar(true)
												}
											}}
										>
											<i className="ri-add-circle-line me-1"></i>
											<span className="btn-text"><JTranslation typeCase="pascal" text={ADD_HOLIDAY} /></span>
										</button>
									</div>
									<Dropdown
										value={year.value}
										options={yearOptions}
										onChange={onYearChange}
										className="custom-dropdown"
									/>
								</div>
							</div>
						)}

					<div className="card-body flex-grow-1 overflow-auto">
						{timeOffRequestList.length > 0 ? (
							timeOffRequestList.map((holiday: HolidayData) => (
								<div
									key={holiday.id}
									className="row px-2 py-2 mb-2 mx-2 d-flex flex-row justify-content-between align-items-center holiday-list-card"
								>
									<div className="d-flex">
										<div className="row flex-grow-1 d-md-flex gy-2">
											<div
												className={`${width <= breakPoint ? 'col-sm-10' : 'col-sm-5'
													} d-flex align-items-center`}
											>
												{checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY) && (
													<div className="flex-grow-0 d-flex align-items-center me-4">
														<i
															className={`ri-circle-fill${dayjs().isBefore(dayjs(holiday.startDate))
																	? ' success'
																	: ' danger'
																}`}
														></i>
													</div>
												)}
												<div className="d-flex flex-column flex-grow-1">
													<div className="d-flex align-items-center">
														<span className="fw-semibold">
															{toPascalCase(holiday?.title)}
														</span>
														{holiday.isPaid && (
															<Badge
																className="custom-badge mx-3"
																value="Paid"
																severity="warning"
															></Badge>
														)}
													</div>
													<div className="holiday-name w-100">
														<>{customDate(holiday)}</>
													</div>
													<div className="d-flex align-items-center mt-1">
														{durationTemplate(holiday)}
													</div>
													{width <= breakPoint && (
														<div className="d-flex holiday-name align-items-center">
															{parsedHolidayType(holiday?.holidayType)}
															{!holiday.isOpenForBusiness && (
																<Badge
																	className="custom-badge mx-3"
																	value={<JTranslation typeCase="pascal" text={BUSINESS_CLOSE} />}
																	severity="danger"
																></Badge>
															)}
														</div>
													)}
												</div>
											</div>

											{width > breakPoint && (
												<div className="col-sm-5 holiday-name d-flex align-items-center">
													{parsedHolidayType(holiday?.holidayType)}
													{!holiday.isOpenForBusiness && (
														<Badge
															className="custom-badge mx-3"
															value={<JTranslation typeCase="pascal" text={BUSINESS_CLOSE} />}
															severity="danger"
														></Badge>
													)}
												</div>
											)}

											{(checkPermission(
												queryClient,
												PermissionKeys.MANAGE_HOLIDAY,
												SubPermissionKeys.ADD
											) ||
												checkPermission(
													queryClient,
													PermissionKeys.MANAGE_HOLIDAY,
													SubPermissionKeys.EDIT
												) ||
												checkPermission(
													queryClient,
													PermissionKeys.MANAGE_HOLIDAY,
													SubPermissionKeys.DELETE
												)) && (
													<div className="col-sm-2 d-flex justify-content-end align-items-center">
														{actionTemplate(holiday)}
													</div>
												)}
											{!checkPermission(queryClient, PermissionKeys.MANAGE_HOLIDAY) && (
												<div className="col-sm-2 d-flex justify-content-end align-items-center">
													<div className="flex-grow-0 d-flex align-items-center me-4">
														<i
															className={`ri-circle-fill${dayjs().isBefore(dayjs(holiday.startDate))
																	? ' success'
																	: ' danger'
																}`}
														></i>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							))
						) : (
							<div className="text-center">
								<img src={HolidayImage} alt="time-off-request" />
								<h5 className="mt-5 mb-3"><JTranslation typeCase="pascal" text={"No Holidays Added"} /></h5>
								<p>
									<JTranslation typeCase="capitalize" text={`Discover the magic of holidays! Our calendar is awaiting your favorite national,
									festive, or regional celebrations.`} />
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default withSidebar(HolidayConfig)
