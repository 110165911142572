import React, { useContext, useEffect, useState } from 'react'
import { JTranslation, jTranslationText, TranslationContext } from '../../helpers/jTranslate'
import { Badge } from 'primereact/badge'
import { checkPermission, getAllWeekDays, getFirstThreeLetter, getStaffId, toPascalCase } from '../../helpers/utils'
import MlxTooltip from '../common/MlxTooltip'
import { FaCaretRight, FaEdit } from 'react-icons/fa'
import { AlertVariant, PageMode, PermissionKeys, toastMessageInitialData } from '../../constants/constants'
import { useQueryClient } from 'react-query'
import { AddAvailabilityResponse, AvailabilityData, AvailabilityFormData, AvailabilityWeekFormData, SingleAvailabilityFormData, ToastMessageProps, Users } from '../../constants/staticTypes'
import dayjs from 'dayjs'
import { DELETE_WARNING, SET_TEMP_AVAILABILITY } from '../../constants/strings'
import AvailabilityDataGrid from './AvailabilityDataGrid'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { useWindowSize } from 'react-use'
import AvailabilitySideBar from './AvailabilitySideBar'
import WarningModal from '../warning_modal/WarningModal'
import ToastAlert from '../alert/ToastAlert'
import { currentWeekDaysString, deleteStaffAvailability, getAvailabilityOfUsers } from '../../helpers/availabilityHelper'
import { AVAILABILITY_LIST, USERS_LIST } from '../../constants/queryKeys'
import useMutationHook from '../../hooks/useMutationHook'
import { Dropdown } from 'primereact/dropdown'
import { useTranslation } from '../../hooks/useTranslation'
import useQueryHook from '../../hooks/useQueryHook'
import { getUsersList } from '../../helpers/manageUsersHelper'
import { AxiosResponse } from 'axios'
import Loader from '../loader/Loader'
import noProfileAvatar from '../../../src/assets/images/placeholder-avatar.jpg'

type BadgeSeverityType = 'success' | 'info' | 'warning' | null | undefined
type Props = {
    getBadgeStatus: (status: string) => BadgeSeverityType;
    parent?: string;
}

export default function MyAvailability({
    getBadgeStatus,
    parent
}: Props) {
    const queryClient = useQueryClient()
	const staffId = getStaffId()
	const currentWeek = currentWeekDaysString()
    const {width: screenWidth} = useWindowSize()
	const availabilityMutation = useMutationHook(queryClient, true)
    const dropdownTranslation = useTranslation('Select', 'pascal');

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [availabilityType, setAvailabilityType] = useState<'REGULAR' | 'TEMPORARY'>('REGULAR')
    const [availabilityList, setAvailabilityList] = useState<AvailabilityData[]>([])
    const [regularAvailability, setRegularAvailability] = useState<AvailabilityData | null>(null)
    const [temporaryAvailabilityList, setTemporaryAvailabilityList] = useState<AvailabilityData[]>([])
	const [deleteAvailability, setDeleteAvailability] = useState<SingleAvailabilityFormData>({}) // delete availability
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [showWarning, setWarning] = useState(false) // Warning popup
	const [warningMessage, setWarningMessage] = useState('') // Warning message
    const [usersList, setUsersList] = useState<Users[]>([])
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [selectedStaffId, setSelectedStaffId] = useState<string>(staffId);

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)
	const { setAvailabilityData } = useContext(ScheduleManagementCtx)

    const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	const [translationText, setTranslatedText] = useState<{
		editAvailability: string,
	}>({ 
		editAvailability: "Edit Regular Availability",
	});
    
    const currentWeekDays = getAllWeekDays(dayjs())

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: "Edit Regular Availability", typeCase: 'pascal', translationContext }),
				])
				
				setTranslatedText({
					editAvailability: translations[0] ?? "Edit Regular Availability",
				})
			} catch {
				setTranslatedText({
					editAvailability: "Edit Regular Availability",
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

    // availability list fetch success
	const onAvailabilityListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const availabilityListData = (res.data.data.lists as AvailabilityData[]) || ([] as AvailabilityData[])
		setAvailabilityList(availabilityListData)
	}

    // fetch availability list
	const availabilityListData = useQueryHook(
		AVAILABILITY_LIST,
		() => {
			return getAvailabilityOfUsers({ staffId: selectedStaffId, startDate: currentWeek[0] })
		},
		onAvailabilityListFetchSuccess
	)

    useEffect(() => {
		const regularAvailabilityData = availabilityList.find((availability) => availability.isRecurring)
		if (regularAvailabilityData) {
			setRegularAvailability(regularAvailabilityData)
		} else {
			setRegularAvailability(null)
		}

		const availabilityListByTemporary = availabilityList.filter(
			(availability) => availability.isRecurring === false
		)
		if (availabilityListByTemporary) {
			setTemporaryAvailabilityList(availabilityListByTemporary)
		}
	}, [availabilityList, regularAvailability])

    useEffect(() => {
        if (selectedStaffId) {
            availabilityListData.refetch(); // Re-fetch the availability list for the new `staffId`
        }
    }, [selectedStaffId]);    
    
	const onAvailabilityDeleteClick = (availability: AvailabilityData) => {
		setDeleteAvailability({ id: availability.id })
		setWarningMessage(DELETE_WARNING)
		setWarning(true)
	}

	const onSuccess = (res: AddAvailabilityResponse) => {
		queryClient.refetchQueries([AVAILABILITY_LIST])
		setShowSideBar(false)
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}
    
	const deleteAvailabilityApiCall = () => {
		deleteStaffAvailability(availabilityMutation, deleteAvailability, onSuccess, onError)
	}

    const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
        const responseData = res?.data?.data.lists as Users[]
        setUsersList(responseData)
    }

    // fetch users list
    const userData = useQueryHook(USERS_LIST, () => getUsersList('', 500, ''), onUserListFetchSuccess)

    // Dropdown options mapped from the preferred names
    const userOptions = usersList.map(user => ({
        label: user.preferredName, // Display name
        value: user.id,      // Unique id for each user
        image: user.signedUrl
    })).sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        if (staffId && !selectedUserId) { // Set default only once when component mounts
            const defaultOption = userOptions.find(option => option.value === staffId);
            if (defaultOption) {
                setSelectedUserId(defaultOption.value);
            }
        }
    }, [staffId, userOptions]);

    // Function to handle dropdown selection
    const handleUserSelection = (e: any) => {
        const selectedUser = e.value; // selected user id
        setSelectedUserId(selectedUser)
        setSelectedStaffId(selectedUser);
    };

    // if (userData.isLoading || availabilityListData.isLoading || availabilityListData.isRefetching) {
	// 	return <Loader />
	// }

    const staffOptionTemplate = (option:any) => {
        return (
            <div className="d-flex align-items-center">
                <img alt={option.name} src={option.image ? option.image : noProfileAvatar} className={`mr-2 user-img-availability`}  />
                <div>{option.label}</div>
            </div>
        );
    };

    const selectedStaffTemplate = (option:any, props:any) => {
        if (option) {
            return (
                <div className="d-flex align-items-center">
                    <img alt={option.name} src={option.image ? option.image : noProfileAvatar} className={`mr-2 user-img-availability`} />
                    <div>{option.label}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

  return (
    <>
    <WarningModal
        show={showWarning}
        title={warningMessage}
        onHide={() => {
            setWarning(false)
            setDeleteAvailability({})
        }}
        callback={() => {
            setWarning(false)
            deleteAvailabilityApiCall()
        }}
    />

    <ToastAlert
        data-testid="toast"
        message={toastMessage.message}
        onClose={() => setToastMessage(toastMessageInitialData)}
        show={toastMessage.show}
        variant={toastMessage.variant}
    />
    {/* availability sidebar */}
    <AvailabilitySideBar
        availabilityType={availabilityType}
        pageMode={mode}
        setMode={setMode}
        setShowSideBar={setShowSideBar}
        setToastMessage={setToastMessage}
        selectedUserId={selectedUserId}
    />
    <div className="card-body flex-grow-1 overflow-auto">
        <div className="h-100 d-flex flex-column">
            {parent === 'staffAvailability' && 
                <div className='mb-4 mt-0'>
                    <div style={{fontWeight: "bold"}}>
                        <JTranslation typeCase="pascal" text={'Select Staff'} />
                    </div>
                    <Dropdown
                        className='availability-dropdown'
                        value={selectedUserId}
                        options={userOptions}
                        onChange={handleUserSelection}
                        optionLabel="label" 
                        optionValue="value"
                        placeholder={dropdownTranslation}
                        itemTemplate={staffOptionTemplate}
                        valueTemplate={selectedStaffTemplate}
                        filter
                    />
                </div>
            }
            {userData.isLoading || availabilityListData.isLoading || availabilityListData.isRefetching ? 
                <Loader />
                :
                <>
                    <div className="d-flex flex-row justify-content-between availability-border">
                        <div className="d-flex align-items-center">
                            <strong><JTranslation typeCase="pascal" text={"Regular Availability"} /></strong>
                            {regularAvailability && (
                                <Badge
                                    className="ms-3 custom-badge"
                                    severity={getBadgeStatus(regularAvailability.actionStatus)}
                                    value={<JTranslation typeCase="pascal" text={toPascalCase(regularAvailability.actionStatus)} />}
                                ></Badge>
                            )}
                        </div>
                        {checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && (
                            <MlxTooltip target='.edit-availability'>
                                <FaEdit
                                    data-test-id={'regular-availability-edit-icon'}
                                    size={22}
                                    className='edit edit-availability'
                                    data-pr-tooltip={translationText.editAvailability}
                                    data-pr-position="left"
                                    onClick={(e) => {
                                        e.preventDefault()

                                        if (
                                            regularAvailability &&
                                            checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY)
                                        ) {
                                            const weekDays = getAllWeekDays(dayjs(regularAvailability.startDate))
                                            const editableData: AvailabilityFormData = {
                                                id: regularAvailability.id,
                                                isRecurring: 'recurring',
                                                startDate: currentWeekDays[0],
                                                endDate: currentWeekDays[6] ?? null,
                                                staffId: selectedUserId ? selectedUserId : '',
                                                weekdays: regularAvailability.weekdays
                                                    ? regularAvailability.weekdays.map((day, index) => {
                                                            const weekDay: AvailabilityWeekFormData = {
                                                                isAllDay: day.isAllDay,
                                                                isAvailable: day.isAvailable,
                                                                weekday: weekDays[index],
                                                                notes: day.notes ?? '',
                                                                availableTimes: day.availableTimes
                                                                    ? day.availableTimes.map((time) => ({
                                                                            from: dayjs(
                                                                                `${weekDays[index].format(
                                                                                    'MM/DD/YYYY'
                                                                                )} ${time.from}`
                                                                            ),
                                                                            to: dayjs(
                                                                                `${weekDays[index].format(
                                                                                    'MM/DD/YYYY'
                                                                                )} ${time.to}`
                                                                            ),
                                                                    }))
                                                                    : [],
                                                            }
                                                            return weekDay
                                                    })
                                                    : [],
                                            }
                                            setAvailabilityData(editableData)
                                            setMode(PageMode.EDIT)
                                            setShowSideBar(true)
                                        }

                                        setAvailabilityType('REGULAR')
                                        setShowSideBar(true)
                                    }}
                                />
                            </MlxTooltip>
                        )}
                    </div>
                    <div className="regular-availability-row">
                        {regularAvailability?.weekdays
                            ? regularAvailability.weekdays.map((day, index) => (
                                    <div
                                        key={index + 1}
                                        className={`p-2 d-flex flex-column justify-content-center align-items-center ${
                                            index + 1 <
                                            (regularAvailability?.weekdays
                                                ? regularAvailability?.weekdays?.length
                                                : 7)
                                                ? 'border-right'
                                                : ''
                                        }`}
                                    >
                                        {screenWidth > 800 ? (
                                            <>
                                                <p>{getFirstThreeLetter(day.weekday)}</p>
                                                <p className={day.isAvailable ? 'success' : 'danger'}>
                                                    {day.isAvailable 
                                                        ? <JTranslation typeCase="pascal" text={'Available'} /> 
                                                        : <JTranslation typeCase="pascal" text={'Not Available'} />
                                                    }
                                                </p>
                                                <p>
                                                    {day.isAllDay && day.isAvailable
                                                        ? <JTranslation typeCase="pascal" text={'All Day'} />
                                                        : day?.availableTimes?.map((times, index) => (
                                                                <p key={index} className="text-warning">
                                                                    {times.from} - {times.to}
                                                                </p>
                                                        ))}
                                                    {!day.isAllDay && day.notes && (
                                                        <p className="text-warning">{day.notes}</p>
                                                    )}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <span>
                                                    <p>{getFirstThreeLetter(day.weekday)}</p>
                                                    <p>
                                                        {day.isAllDay && day.isAvailable
                                                            ? <JTranslation typeCase="pascal" text={'All Day'} />
                                                            : day?.availableTimes?.map((times, index) => (
                                                                    <p key={index} className="text-warning">
                                                                        {times.from} - {times.to}
                                                                    </p>
                                                            ))}
                                                        {!day.isAllDay && day.notes && (
                                                            <p className="text-warning">{day.notes}</p>
                                                        )}
                                                    </p>
                                                </span>
                                                <p className={day.isAvailable ? 'success' : 'danger'}>
                                                    {day.isAvailable 
                                                        ? <JTranslation typeCase="pascal" text={'Available'} /> 
                                                        : <JTranslation typeCase="pascal" text={'Not Available'} />
                                                    }
                                                </p>
                                            </>
                                        )}												
                                    </div>
                                ))
                            : currentWeekDays?.map((day, index) => (
                                    <div
                                        key={index + 1}
                                        className={`p-2 d-flex flex-column justify-content-center align-items-center ${
                                            index + 1 < currentWeekDays?.length ? 'border-right' : ''
                                        }`}
                                    >
                                        <p>{day.format('dddd')}</p>
                                        <p className="success"><JTranslation typeCase="pascal" text={"Available"} /></p>
                                        <p><JTranslation typeCase="pascal" text={"All Day"} /></p>
                                    </div>
                                ))}
                    </div>

                    <div className="mt-5 d-flex flex-row justify-content-between">
                        <div className="d-flex align-items-center">
                            <b><JTranslation typeCase="pascal" text={"Temporary Availability"} /></b>
                        </div>
                        {checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && (
                            <button
                                data-test-id={'temporary-availability-add-btn'}
                                className="btn btn-custom-primary d-flex  icon-only-btn"
                                type="button"
                                onClick={() => {
                                    if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && regularAvailability) {
                                        const weekDays = getAllWeekDays(dayjs(regularAvailability.startDate))
                                        const editableData: AvailabilityFormData = {
                                            id: regularAvailability.id,
                                            isRecurring: 'recurring',
                                            startDate: currentWeekDays[0],
                                            endDate: currentWeekDays[6] ?? null,
                                            staffId: selectedUserId ? selectedUserId : '',
                                            weekdays: regularAvailability.weekdays
                                                ? regularAvailability.weekdays.map((day, index) => {
                                                        const weekDay: AvailabilityWeekFormData = {
                                                            isAllDay: day.isAllDay,
                                                            isAvailable: day.isAvailable,
                                                            weekday: weekDays[index],
                                                            notes: day.notes ?? '',
                                                            availableTimes: day.availableTimes
                                                                ? day.availableTimes.map((time) => ({
                                                                        from: dayjs(
                                                                            `${weekDays[index].format(
                                                                                'MM/DD/YYYY'
                                                                            )} ${time.from}`
                                                                        ),
                                                                        to: dayjs(
                                                                            `${weekDays[index].format(
                                                                                'MM/DD/YYYY'
                                                                            )} ${time.to}`
                                                                        ),
                                                                }))
                                                                : [],
                                                        }
                                                        return weekDay
                                                })
                                                : [],
                                        }
                                        setAvailabilityData(editableData)
                                    }
                                    setMode(PageMode.ADD)
                                    setAvailabilityType('TEMPORARY')
                                    setShowSideBar(true)
                                }}
                            >
                                <i className="ri-add-circle-line me-1"></i>
                                <span className="btn-text"><JTranslation typeCase="pascal" text={SET_TEMP_AVAILABILITY} /></span>
                            </button>
                        )}
                    </div>

                    {temporaryAvailabilityList.length ? (
                        <AvailabilityDataGrid
                            queryClient={queryClient}
                            tableData={temporaryAvailabilityList}
                            setMode={setMode}
                            setToastMessage={setToastMessage}
                            setShowSideBar={setShowSideBar}
                            showSideBar={showSideBar}
                            onAvailabilityDeleteClick={onAvailabilityDeleteClick}
                            setAvailabilityType={setAvailabilityType}
                            selectedUserId={selectedUserId}
                        />
                    ) : (
                        <div className="mt-1 d-flex flex-column justify-content-center">
                            <div className="p-4 d-flex align-items-center mt-2 add-on-group-box">
                                <div className="empty-addon-group">
                                    <FaCaretRight size={18} className="noAddon-icon" />
                                    <span className="mx-1"><JTranslation typeCase="pascal" text={"No Temporary Availability"} /></span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            }
        </div>
    </div>
    </>
  )
}
