import React, { useEffect, useState, useContext } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AxiosResponse } from "axios";
import { BACK, ITEM_NOT_AVAILABLE, RECOMMENDATION_WITH, VIEW_WINE_HISTORY, REDIRECTION_FROM_ITEM_WARNING, REDIRECTION_FROM_ITEM_TITLE, KEY_INGREDIENTS, VIEW_DETAILS, EACH, ADD_ONS } from "../../../constants/strings";
import { BreadCrumb } from 'primereact/breadcrumb';
import { capitalizeFirstLetter, isJSONObjectEmpty, getDomainFromURL, toPascalCase, getNumberWithTwoDecimals, menuAndCategorySlugsFromItem, unescapeHtml } from "../../../helpers/utils";
import { CommonCtx } from "../../../context/CommonCtxProvider";
import { FileType, TENANT_NAME, SELECTED_MENU, MENU_RERENDER_INTERVAL } from "../../../constants/constants";
import { findMenuValuesById, findMenuAndCategoryName, getMenuItemsDetails, getDefaultPrice, getMinPriceArrayWithoutSort, shouldShowMenuCard, getMenuItemsPublic, getBreadCrumbPath, getMenuItemsDetailsBySlug } from "../../../helpers/menuViewHelper";
import { FnBCtx } from "../../../context/MoveItemCtxProvider";
import { JTranslation, jTranslationText, TranslationContext } from '../../../helpers/jTranslate';
import { MENU_ITEMS, MENU_ITEM_DETAIL, PUBLIC_MENU } from "../../../constants/queryKeys";
import { MenuItem, Price, Menu, Gallery } from "../../../constants/staticTypes";
import { routes } from "../../../constants/routes";
import { Skeleton } from "primereact/skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTrackEvent } from "../../../hooks/useTrackEvent";
import { v4 as uuidv4 } from "uuid";
import CommonModal from "../../common_modal/CommonModal";
import GalleryViewWithFooter from "./GalleryViewWithFooter";
import Loader from "../../loader/Loader";
import placeholderImage from "../../../assets/images/menu/540x360.png";
import useQueryHook from "../../../hooks/useQueryHook";
import withPublicHeader from "../../../hoc/withPublicHeader";
import useCustomBackButton from "../../../hooks/useCustomBackButton";

let intervalId: any

type ItemState = {
  item: MenuItem,
  previousPath?: string
}

function ItemView() {
  useCustomBackButton(routes.menu_view)
  const navigate = useNavigate()
  const location = useLocation()
  const itemParams = location.state as ItemState
  const queryClient = useQueryClient()
  const TENANT = localStorage.getItem(TENANT_NAME)
  const storedSelectedMenu = localStorage.getItem(SELECTED_MENU)
  const { trackEvent } = useTrackEvent()
  // CONTEXT VARIABLES
  const { fnBGlobalSettings } = useContext(CommonCtx)
  const { rerender, setRerender, userSelectedMenu } = useContext(FnBCtx)
  // URL PARAMS
  const { menuSlug, categorySlug, itemSlug } = useParams()
  // STATE VARIABLES
  const [galleryImages, setGalleryImages] = useState<Gallery[]>([])
  const [item, setItem] = useState<MenuItem>()
  const [showWarning, setShowWarning] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [breadCrumbItems, setBreadCrumbItems] = useState<{ label: string, url?: string }[]>([])
  const translationContext = useContext(TranslationContext)

  const TranslateText = async (text: string) => {
    let returnText = text
    try {
      const translatedText = await jTranslationText({
        text,
        translationContext,
      })
      returnText = translatedText || ""
    }
    catch (e) {
      console.log("Translation error on ItemView:", e)
    }
    return returnText
  }
  // fetch menu items
  const listDetails = useQueryHook(
    PUBLIC_MENU,
    () => getMenuItemsPublic(queryClient),
    () => { },
    undefined,
    true,
    false
  )
  const publicMenuList = queryClient.getQueryData(PUBLIC_MENU) as AxiosResponse<
    any,
    any
  >
  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (
        publicMenuList &&
        !isJSONObjectEmpty(publicMenuList.data.data.list) &&
        !isJSONObjectEmpty(item)
      ) {
        const path = getBreadCrumbPath({
          data: publicMenuList.data.data.list,
          id: item?.id ?? '',
        })

        try {
          const menuName = await TranslateText(path?.menuName || "")
          const categoryName = await TranslateText(path?.categoryName || "")
          const itemName = await TranslateText(path?.itemName || "")

          if (isMounted) {
            if (path) {
              setBreadCrumbItems([
                { label: menuName || "", url: `${routes.menu_view}/${menuSlug}` },
                { label: categoryName || "", url: `${routes.menu_view}/${menuSlug}/${categorySlug}` },
                { label: itemName || "", url: `#` },
              ])
            }
          }
        } catch (error) {
          // Handle any errors here
        }
      }
    }

    fetchData()

    return () => {
      // Cleanup function to set isMounted to false when the component unmounts
      isMounted = false
    }
  }, [publicMenuList, item, translationContext])

  // fetch item details
  const itemDetails = useQueryHook(
    MENU_ITEM_DETAIL,
    () => itemParams
      ? getMenuItemsDetails(queryClient, itemParams?.item?.id as string)
      : getMenuItemsDetailsBySlug(queryClient, itemSlug as string),
    (res) => {
      let data = res.data.data.details as MenuItem
      setItem(data)
    },
    undefined,
    true,
    true
  )

  useEffect(() => {
    itemDetails.refetch(); // Trigger the query again when itemSlug changes
  }, [itemSlug]);

  // get item price with field names
  const getItemPricesAndFieldNames = (itemPrices: Price[]) => {
    return getDefaultPrice(itemPrices).map((item) => {
      let fieldName = itemPrices.filter((price) =>
        Number(price.fieldValue) === Number(item) ? price.fieldName : null
      )
      return (
        <div
          key={uuidv4()}
          className='d-flex mb-1 align-items-baseline flex-column'
        >
          <div className='price-ft  w-100'>
            <div className='d-flex text-nowrap overflow-hidden align-items-baseline'>
              <div className='flex-grow-0 me-2'>
                {item === 'Infinity' ? '...' : `$${item} `}
              </div>
              <span className='flex-grow-1 overflow-hidden text-ellipsis'>
                <JTranslation text={fieldName[0]?.fieldName} />
              </span>
            </div>
          </div>
        </div>
      )
    })
  }

  // get list of prices with display price on top
  const getListOfPrices = (item: MenuItem) => {
    let prices: JSX.Element[] = []
    let defaultPriceFound = false
    item.itemPrices.forEach((price, index) => {
      if (price.isDefault) {
        defaultPriceFound = true
        prices.unshift(
          <h3
            key={uuidv4()}
            className='item-ttl item-price mb-2 me-3 display-price'
          >
            ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
            <span>
              {' '}
              <JTranslation text={price?.fieldName || ''} />
            </span>
          </h3>
        )
      } else {
        prices.push(
          <h3 key={uuidv4()} className='item-ttl item-price mb-2 me-3'>
            ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
            <span>
              {' '}
              <JTranslation text={price?.fieldName || ''} />
            </span>
          </h3>
        )
      }
    })
    // condition to make the first price bold if no display price is set
    if (!defaultPriceFound) {
      prices = []
      item.itemPrices.forEach((price, index) => {
        if (index === 0) {
          prices.push(
            <h3
              key={uuidv4()}
              className='item-ttl item-price mb-2 me-3 display-price'
            >
              ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
              <span>
                {' '}
                <JTranslation text={price?.fieldName || ''} />
              </span>
            </h3>
          )
        } else {
          prices.push(
            <h3 key={uuidv4()} className='item-ttl item-price mb-2 me-3'>
              ${getNumberWithTwoDecimals(+(price?.fieldValue))}{' '}
              <span>
                {' '}
                <JTranslation text={price?.fieldName || ''} />
              </span>
            </h3>
          )
        }
      })
    }

    return prices
  }

  const RedirectWarningModalContent = <p>{REDIRECTION_FROM_ITEM_WARNING}</p>

  const closeModalAndRedirect = () => {
    setShowWarning(false)
    setRerender(rerender + 1)
    // redirect to menu view page
    window.location.replace(routes.menu_view)
  }

  useEffect(() => {
    if ((itemParams === undefined || itemParams === null) && itemSlug === undefined) {
      // go back if user directly access page
      navigate(-1)
    } else {
      let res = queryClient.getQueryData(MENU_ITEM_DETAIL) as AxiosResponse<
        any,
        any
      >
      if (res !== undefined) {
        const data = res.data.data.details as MenuItem
        if (data.id !== undefined && data.id === itemParams?.item?.id) {
          setItem(data)
        }
      }
      queryClient.fetchQuery(MENU_ITEMS, () => { }) // nosonar
    }
  }, []) // eslint-disable-line

  // set image / video gallery
  useEffect(() => {
    if (!initialLoad) {
      window.scrollTo(0, 0)
      setInitialLoad(true)
    }

    let galleryArray: Gallery[] = []
    item?.signedPhotoKeys?.forEach((img) => {
      let newImage = {
        src: img,
        type: FileType.IMAGE,
      }
      galleryArray.push(newImage)
    })

    item?.signedVideoKeys?.forEach((video) => {
      let newVideo = {
        src: video,
        type: FileType.VIDEO,
      }
      galleryArray.push(newVideo)
    })
    setGalleryImages(galleryArray)
  }, [item])

  /**
   * if
   * the isDefault is true bring that to the start of the array
   * else
   * find the lowest price and bring that to the start of the array
   */
  useEffect(() => {
    let itemPrices = item?.itemPrices
    let isDefaultFound = false

    itemPrices?.forEach((item, index) => {
      if (item.isDefault) {
        isDefaultFound = true
        itemPrices?.unshift(item)
        itemPrices?.splice(index + 1, 1)
      }
    })

    if (!isDefaultFound && itemPrices !== undefined) {
      getMinPriceArrayWithoutSort(itemPrices)
    }

    return () => {
      itemPrices?.forEach((item, index) => {
        if (item.isDefault) {
          isDefaultFound = true
          itemPrices?.unshift(item)
          itemPrices?.splice(index + 1, 1)
        }
      })
    }
  }, [item])

  // effect to get the selected menu for local storage
  useEffect(() => {
    if (storedSelectedMenu) {
      const menu: Menu = JSON.parse(storedSelectedMenu)
      const { periodicSchedules } = menu
      const checkCardStatus = () => {
        let isParentMenuDisplayed = shouldShowMenuCard(periodicSchedules)
        setShowWarning(!isParentMenuDisplayed)
      }

      checkCardStatus()

      // checking card status on interval
      intervalId = setInterval(() => {
        checkCardStatus()
      }, MENU_RERENDER_INTERVAL)

      // cleanup
      // stop the setInterval function on page unmount
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [])


  return (
    <>
      {(
        itemDetails.isLoading ||
        itemDetails.isRefetching ||
        itemDetails.isFetching ||
        item?.id === undefined
      ) ? (
        <Loader />
      ) : (
        <div className='container'>
          {/* redirection modal popup */}
          <CommonModal
            show={showWarning}
            modalContent={RedirectWarningModalContent}
            title={REDIRECTION_FROM_ITEM_TITLE}
            hideCancel
            modalSize='md'
            onHide={closeModalAndRedirect}
            callback={closeModalAndRedirect}
            hideCloseButton
          />

          <div className='row mb-3 mt-3'>
            <div className='col-lg-12 p-0'>
              <div className='card'>
                <div className='card-header p-0 card-breadcrumb'>
                  <div className=' '>
                    <div className='col-md-12 d-flex align-items-center p-2'>
                      <div
                        className='button btn  back-btn back-btn-new'
                        title={BACK}
                        data-testid="back-btn"
                        onClick={() => {
                          if(itemParams?.previousPath) {
                            navigate(itemParams?.previousPath, { replace: true })
                          } else {
                            navigate(-1)
                          }
                        }}
                      >
                        <i className='ri-arrow-left-line'></i>
                      </div>
                      {(
                        listDetails.isLoading ||
                        listDetails.isRefetching ||
                        listDetails.isFetching
                      ) ? (
                        <div className='row ms-2'>
                          <div className='col-4'>
                            <Skeleton width='6rem' height='2rem'></Skeleton>
                          </div>
                          <div className='col-4'>
                            <Skeleton width='6rem' height='2rem'></Skeleton>
                          </div>
                          <div className='col-4'>
                            <Skeleton width='8rem' height='2rem'></Skeleton>
                          </div>
                        </div>
                      ) : (
                        <BreadCrumb
                          className='custom-breadcrumb'
                          model={breadCrumbItems}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className='card-body px-0'>
                  <div className='row mt-3 '>
                    <div className='col-md-5 col-lg-6 image-large-sticky'>
                      {/* <div className='item-list-image-large mb-3 p-0'>
                        <img
                          src={
                            (item?.signedPhotoKeys &&
                              item.signedPhotoKeys[0]) ||
                            placeholderImage
                          }
                          className=''
                          alt=''
                        />
                      </div> */}
                      <GalleryViewWithFooter
                        galleryImages={galleryImages}
                      />
                    </div>
                    <div className='col-md-7 col-lg-6 item-view-main '>
                      {/* <div className='item-view-bg shadow-sm rounded p-3'> */}
                      <div className='p-3'>
                        <h3 className='item-ttl'>
                          <JTranslation
                            text={item?.itemName}
                          //   type='html'
                          />
                        </h3>

                        <h6 className='text-danger'>
                          {' '}
                          <JTranslation
                            text={item?.isAvailable ? '' : ITEM_NOT_AVAILABLE}
                          //   type='html'
                          />
                        </h6>
                        <div>
                          {item.itemTag?.split(',').map((tag) => (
                            <span key={uuidv4()} className="item-badge my-2">{<JTranslation text={tag} />}</span>
                          ))}
                        </div>
                        <div className="product-description text-secondary">
                        <JTranslation
                          text={
                            unescapeHtml(item?.itemDescription)
                          }
                          type='html'
                        />
                        </div>
                        <div className='price-main mt-4 mb-4'>
                          {item.isMarketPrice ? (
                            <div className='market-price-text'>
                              <JTranslation
                                text={fnBGlobalSettings.marketPriceLabel}
                              />
                            </div>
                          ) : (
                            getListOfPrices(item)
                          )}
                        </div>

                        {item?.historyInfo?.title && (
                          <div
                            className='view-wine-history mb-4 mt-1'
                            onClick={() => {
                              navigate(`${routes.menu_view}/${menuSlug}/${categorySlug}/${itemSlug}/history`)

                              findMenuAndCategoryName({ menuItemId: item.id, selectedMenu: userSelectedMenu })
                                .then((result) => {
                                  if (result) {
                                    const { categoryName, menuName } = result
                                    const { itemName, itemPrices } = item
                                    const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                    // Google Analytics
                                    trackEvent({
                                      eventName: 'view_wine_history',
                                      label: 'Click on "view history" link on item view page',
                                      value: {
                                        page_path: '/item-view',
                                        page_location: `${getDomainFromURL()}/item-view`,
                                        menu_name: menuName,
                                        menu_category: categoryName,
                                        menu_item_name: itemName,
                                        menu_item_price: price,
                                        wine_origin: item.historyInfo.originCountry
                                      }
                                    });
                                  }
                                })
                            }}
                          >
                            <i className='ri-goblet-fill'></i>{' '}
                            <JTranslation
                              text={VIEW_WINE_HISTORY}
                            //   type='html'
                            />
                          </div>
                        )}

                        {
                          (
                            (item?.selectedAddonsData && item?.selectedAddonsData.length > 0) ||
                            (item?.selectedAddonsGroupsData && item?.selectedAddonsGroupsData.length > 0)
                          )
                          && (
                            <div className='add-extra-box mb-5'>
                              <h5 className="text-muted mb-3">
                                {item?.selectedAddonsData && item.selectedAddonsData.length ? (
                                  <JTranslation text={ADD_ONS} />
                                ) : null}
                              </h5>

                              <Accordion >
                                {item?.selectedAddonsGroupsData && item?.selectedAddonsGroupsData
                                  .sort((a: any, b: any) => {
                                    if (a.addonGroupPrice !== b.addonGroupPrice) {
                                      return a.addonGroupPrice - b.addonGroupPrice;
                                    }
                                    return a?.groupData.addonGroupName.localeCompare(b?.groupData.addonGroupName)
                                  })
                                  .map(addOnGroup => (
                                    <AccordionTab className="accordion-extra" data-testid={"addon-group#" + addOnGroup.addonGroupId} key={addOnGroup.addonGroupId} header={
                                      <div className="d-flex align-items-center justify-content-between"
                                        onClick={() => {
                                          // Google Analytics
                                          trackEvent({
                                            eventName: "expand_addon",
                                            value: {
                                              menu_addon_group: toPascalCase(addOnGroup?.groupData.addonGroupName),

                                            },
                                          });
                                        }
                                        }>
                                        <span className="add-on-title text-dark">{
                                          <JTranslation
                                            text={toPascalCase(addOnGroup?.groupData.addonGroupName)}
                                          />
                                        }</span>
                                        <span className="add-on-title text-dark text-nowrap">${getNumberWithTwoDecimals(+addOnGroup?.groupData.addonGroupPrice)} <JTranslation text={EACH} /></span>
                                      </div>
                                    }>
                                      <ul className="row extra-item-list">
                                        {addOnGroup?.groupData?.selectedAddonsData
                                          .sort((a: any, b: any) => a.addonName.localeCompare(b.addonName))
                                          .map(addOn => (
                                            <li key={addOn.addonId} className="col-sm-6">
                                              <div className="d-flex align-items-center">
                                                <span className="extra-item-name d-flex"><i className="ri-checkbox-blank-circle-fill text-muted extra-list-icon"></i> {
                                                  <JTranslation
                                                    text={toPascalCase(addOn.addonName)}
                                                  />}</span>
                                              </div>
                                            </li>
                                          ))}
                                      </ul>
                                    </AccordionTab>
                                  ))}
                              </Accordion>

                              {
                                item?.selectedAddonsData && item?.selectedAddonsData.length > 0 && (
                                  <div className="mt-2 px-3 add-on-list">
                                    {
                                      item?.selectedAddonsData
                                        .sort((a: any, b: any) => {
                                          if (a.addonPrice !== b.addonPrice) {
                                            return a.addonPrice - b.addonPrice;
                                          }
                                          return a.addonName.localeCompare(b.addonName)
                                        })
                                        .map((addOn, index, { length }) => (
                                          <div
                                            key={addOn.addonId}
                                            className={
                                              "d-flex align-items-center justify-content-between py-2" +
                                              `${index !== length - 1 ? " border-line" : ""}`
                                            }
                                          >
                                            <span className="text-dark">{
                                              <JTranslation
                                                text={toPascalCase(addOn?.addonName)}
                                              />
                                            }</span>
                                            <span className="text-dark fw-bold text-nowrap">
                                              ${getNumberWithTwoDecimals(+addOn?.addonPrice)}
                                            </span>
                                          </div>
                                        ))
                                    }
                                  </div>
                                )
                              }

                            </div>
                          )}

                        {item?.selectedKeyIngredientsData && item?.selectedKeyIngredientsData.length > 0 && (
                          <div className='add-extra-box mb-5'>
                            {item.selectedKeyIngredientsData.length ? (
                              <div className='d-flex align-items-center mb-3'>
                                <h5 className='mb-0 text-secondary'>
                                  <JTranslation
                                    text={toPascalCase(KEY_INGREDIENTS)}
                                  //   type='html'
                                  />{' '}
                                </h5>
                              </div>
                            ) : null}
                            <div className='key-ingredients-container'>
                              {item.selectedKeyIngredientsData // nosonar
                                .sort((a, b) =>
                                  a.ingredientName.localeCompare(
                                    b.ingredientName
                                  )
                                ) // Sort in ascending order based on ingredientName
                                .map((ingredient) => (
                                  <div
                                    className='d-flex key-ingredients-link align-items-center mb-2'
                                    key={uuidv4()}
                                    data-testid={"ingredient#" + ingredient.id}
                                    onClick={() => {
                                      navigate(`${routes.menu_view}/${menuSlug}/${categorySlug}/${itemSlug}/${ingredient.urlSlug}`)

                                      // Google Analytics
                                      findMenuAndCategoryName({ menuItemId: item.id, selectedMenu: userSelectedMenu })
                                        .then((result) => {
                                          if (result) {
                                            const { categoryName, menuName } = result
                                            const { itemName, itemPrices } = item
                                            const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                            trackEvent({
                                              eventName: 'view_ingredient',
                                              label: 'Click on "view more" button on ingredients list',
                                              value: {
                                                page_path: '/item-view',
                                                page_location: `${getDomainFromURL()}/item-view`,
                                                menu_name: menuName,
                                                menu_category: categoryName,
                                                menu_item_name: itemName,
                                                menu_item_price: price,
                                                ingredient_name: ingredient.ingredientName
                                              }
                                            });
                                          }
                                        })

                                      window.scrollTo(0, 0)
                                    }
                                    }
                                  >
                                    <div className='ing-link icon me-2'>
                                      <i className='ri-link'></i>
                                    </div>
                                    <div className='ing-link ingredients-name ellipsis'>
                                      <JTranslation
                                        text={toPascalCase(ingredient.ingredientName)}
                                      />
                                    </div>
                                    <div className='ing-link bullet white-space-nowrap text-muted'>
                                      <JTranslation
                                        text={VIEW_DETAILS}
                                      />
                                      <i className='ri-arrow-right-line ms-1'></i>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}

                        <div className='additional-info-box mb-3'>
                          {item?.additionalInfo?.map(
                            (additionalInfo, index) => {
                              // if (additionalInfo.textContent !== null) {
                              return (
                                <React.Fragment key={uuidv4()}>
                                  <div className="text-secondary">
                                  <JTranslation
                                    text={unescapeHtml(additionalInfo?.textContent || '')}
                                    type='html'
                                  />
                                  </div>
                                  {/* {parse(additionalInfo.textContent)} */}
                                </React.Fragment>
                              )
                              // }
                              // return <></>
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {item.suggestedItemsData &&
                    item.suggestedItemsData?.length > 0 && (
                      <div className='row mb-3 recomendation-ttle mt-5'>
                        <h5 className='fw-bold'>
                          <JTranslation
                            text={`${capitalizeFirstLetter(TENANT)}
                            ${RECOMMENDATION_WITH}
                            <span className="asdf">${item?.itemName}</span>`}
                            type='html'
                          />
                        </h5>
                      </div>
                    )}

                  <div className='row'>
                    {item.suggestedItemsData?.map((suggestion) => {
                      return (
                        <div
                          key={uuidv4()}
                          className='col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3'
                          data-testid={"suggested-item#" + suggestion.id}
                          onClick={() => {
                            window.scrollTo(0, 0)
                            const menuList = publicMenuList.data.data.list as Menu[]
                            const { menuSlug, categorySlug } = menuAndCategorySlugsFromItem(menuList, suggestion.id as string)
                            navigate(
                              `${routes.menu_view}/${menuSlug}/${categorySlug}/${suggestion.urlSlug}${location.search}`,
                              {
                                state: {
                                  item: { ...suggestion },
                                  previousPath: `${routes.menu_view}/${menuSlug}/${categorySlug}/${item.urlSlug}${location.search}`
                                },
                              }
                            )
                            // Google Analytics
                            findMenuValuesById({ menu: menuList, targetId: suggestion.id! })
                              .then((result) => {
                                if (result) {
                                  const { categoryName, menuName, itemName, itemPrices } = result
                                  const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))
                                  trackEvent({
                                    eventName: 'select_menu_pairing',
                                    value: {
                                      menu_name: menuName,
                                      menu_category: categoryName,
                                      menu_item_name: itemName,
                                      menu_item_price: price,
                                    }
                                  });
                                }
                              })
                          }}
                        >
                          <div
                            key={uuidv4()}
                            className='item-list-main shadow-custom item-recommendation p-2'
                          >
                            <div className='item-list-image me-2 col-4  p-0'>
                              <img
                                src={
                                  (suggestion?.signedPhotoKeys?.length &&
                                    suggestion.signedPhotoKeys[0]) ||
                                  placeholderImage
                                }
                                className=''
                                alt=''
                              />
                            </div>

                            <div className='item-list-content col-8  p-2'>
                              <div className='title mb-2'>
                                <JTranslation
                                  text={suggestion.itemName}
                                />{' '}
                              </div>

                              <div className='mb-2'>
                                {fnBGlobalSettings?.showMenuSuggestedItemCardPrice && (
                                  <div className='price-ft display-price'>
                                    {!suggestion.isMarketPrice &&
                                      getDefaultPrice(suggestion.itemPrices) ? (
                                      getItemPricesAndFieldNames(
                                        suggestion.itemPrices
                                      )
                                    ) : (
                                      <div className='market-price-text'>
                                        <JTranslation
                                          text={
                                            fnBGlobalSettings.marketPriceLabel
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div >
          </div >

          <div className='row  pb-3'>
            <div className='col-md-12 p-0'>
              <div className='adbox-container '>
                <div className='d-flex align-items-ceneter'>
                  {/* <div className='flex-grow-1'>
                    <img src={adLeftImg} alt='' />
                  </div> */}
                  <div className='flex-grow-1 p-5 txt-section'>
                    <h3>
                      <JTranslation text='Win a Free Dinner' />
                    </h3>
                    <h4 className='mb-3'>
                      <JTranslation text='By Reviewing Us' />
                    </h4>
                    <button
                      className='btn  btn-lg  btn-custom-primary'
                      data-testid="take-survey-btn"
                      onClick={() =>
                        navigate(`${routes.survey_guest}${location.search}`)
                      }
                    >
                      {' '}
                      <JTranslation text='Take Survey' />
                      <i className='ri-arrow-right-line'></i>
                    </button>
                  </div>
                  <div className='overlay'></div>
                </div>
              </div>
            </div>
          </div>
        </div >
      )
      }
    </>
  )
}
export default withPublicHeader(ItemView);