import { useEffect, useState, useContext, useCallback } from "react";
import { AlertVariant, SPECIAL_MENU_CODE } from "../../constants/constants";
import { AxiosResponse } from "axios";
import { CommonCtx } from "../../context/CommonCtxProvider";
import { CUSTOM_MENU_LIST_GUEST_VIEW, SELECT_MENU_ITEM } from "../../constants/queryKeys";
import { CustomMenuGuestView, Price, LocationState, FnBGlobalSetting } from "../../constants/staticTypes";
import { FnBCtx } from "../../context/MoveItemCtxProvider";
import { getDefaultPrice, saveMenuSelection, getValueAfterHash, findMenuAndCategoryName } from "../../helpers/menuViewHelper";
import { getDomainFromURL, isQrScanPresent, unescapeHtml } from "../../helpers/utils";
import { getGuestViewCustomMenuList } from "../../helpers/customMenuHelper";
import { ITEM_NOT_AVAILABLE } from "../../constants/strings";
import { JTranslation } from '../../helpers/jTranslate'
import { routes } from "../../constants/routes";
import { ScrollTop } from 'primereact/scrolltop';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { v4 as uuidv4 } from 'uuid'
import ErrorPage from "../error_page/ErrorPage";
import Loader from "../../components/loader/Loader";
import placeholderImage from "../../assets/images/menu/540x360.png";
import ToastAlert from "../../components/alert/ToastAlert";
import useQueryHook from "../../hooks/useQueryHook";
import withPublicHeader from "../../hoc/withPublicHeader";

function SpecialMenuGuestView() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const { menuCode } = useParams()
  const { trackEvent } = useTrackEvent()
  // CONTEXT VARIABLES
  const { guestFnbGlobalSettings, setGuestFnbGlobalSettings } = useContext(CommonCtx)
  const { userSelectedMenu, setUserSelectedMenu } = useContext(FnBCtx)
  // STATE VARIABLES
  const [selectedMenu, setSelectedMenu] = useState<CustomMenuGuestView>();
  const [isMenuActive, setIsMenuActive] = useState(true)
  const [specialMenuName, setSpecialMenuName] = useState("")
  // TOAST VARIABLES
  const [showToast, setToast] = useState(false); // Toast alert state
  const [toastMessage, setToastMessage] = useState(""); // Toast message
  const [toastVariant, setToastVariant] = useState(""); // Toast Variant

  useEffect(() => {
    if (isQrScanPresent()) {
      // Google Analytics
      trackEvent({
        eventName: 'qr_scan',
        value: {
          qr_link_destination: '/special-menu',
        }
      });
    }
  }, []) // eslint-disable-line

  useEffect(() => {
		setUserSelectedMenu(selectedMenu)
	}, [selectedMenu]) // eslint-disable-line

  // set menu item from location state
  const setMenuItemFromLocationState = useCallback((data: CustomMenuGuestView) => {
    const selectedMenuItem = data;
    saveMenuSelection(queryClient, selectedMenuItem);
    setSelectedMenu(selectedMenuItem);
  }, [locationState, queryClient]) // eslint-disable-line

  // effect to store the menu code in local storage
  useEffect(() => {
    if (menuCode) {
      localStorage.setItem(SPECIAL_MENU_CODE, menuCode)
    }
  }, [menuCode])

  useEffect(() => {
    // set data from cache if exist before api call
    const res = queryClient.getQueryData(CUSTOM_MENU_LIST_GUEST_VIEW) as AxiosResponse<any, any>;
    const selectedItem = queryClient.getQueryData(SELECT_MENU_ITEM) as CustomMenuGuestView;
    if (res !== undefined) {
      let menuList = res.data.data as CustomMenuGuestView;
      menuList.isActive ? setIsMenuActive(true) : setIsMenuActive(false)
      setSpecialMenuName(menuList.menuName)

      if (selectedItem?.id !== undefined) {
        setSelectedMenu(selectedItem);
      } else if (locationState?.menuId !== undefined) {
        setMenuItemFromLocationState(menuList);
        setTimeout(() => window.scrollTo(0, 0), 100);
      } else {
        setSelectedMenu(menuList);
      }
    }

    // display success toast after guest submit survey
    if (locationState?.message !== undefined) {
      setToastVariant(AlertVariant.SUCCESS);
      setToastMessage(locationState.message);
      setToast(true);
      window.history.replaceState({}, document.title);
    }

  }, []); // eslint-disable-line

  const onError = () => {
    setIsMenuActive(false)
  }

  // fetch menu items
  const fetchMenu = useQueryHook(CUSTOM_MENU_LIST_GUEST_VIEW, () => getGuestViewCustomMenuList(menuCode!), (res) => {
    let menuList = res.data.data as CustomMenuGuestView
    menuList.isActive ? setIsMenuActive(true) : setIsMenuActive(false)
    setSpecialMenuName(menuList.menuName)
    let globalSetting = res.data.data.globalSettings as FnBGlobalSetting
    setGuestFnbGlobalSettings(globalSetting)
    const selectedItem = queryClient.getQueryData(SELECT_MENU_ITEM) as CustomMenuGuestView
    if (selectedItem === undefined && Object.keys(menuList).length > 0) {
      setSelectedMenu(menuList)
    }
  }, onError, true, true);

  // get item tags
  const getItemTags = (itemTag: string) => {
    const tags = itemTag.split(",");
    return tags.map((tag, index) => {
      return (
        <span key={uuidv4()} className='item-badge mb-1 mt-1'>
          <JTranslation text={tag} />
        </span>
      )
    });
  }

  // get item price with field names
  const getItemPricesAndFieldNames = (itemPrices: Price[]) => {
    return getDefaultPrice(itemPrices).map((item) => {
      let fieldName = itemPrices.filter((price) => Number(price.fieldValue) === Number(item) ? price.fieldName : null)
      return (
        <div
          key={uuidv4()}
          className='d-flex mb-1 align-items-baseline flex-column'
        >
          <div className='price-ft w-100'>
            <div className='d-flex text-nowrap overflow-hidden align-items-baseline'>
              <div className='flex-grow-0 me-2'>${item}</div>
              <span className='flex-grow-1 overflow-hidden text-ellipsis'>
                <JTranslation text={fieldName[0]?.fieldName} />

              </span>
            </div>
          </div>
        </div>
      )
    })
  }

  if (fetchMenu.isLoading) {
    return <Loader />
  }


  return (
    <>
      {
        isMenuActive ? (
          <>
            <div className="position-relative">
              <ToastAlert
                data-testid="toast"
                show={showToast}
                onClose={setToast}
                message={toastMessage}
                variant={toastVariant}
              />
            </div>

            <ScrollTop target='parent' className='scroll-top' behavior='smooth'/>

            <button className="survey-floating-btn">
              <i className="ri-survey-line"></i>
            </button>

            <div className="container guest-view-menu">
              <div className="row mb-5">
                <div className="col-lg-12 p-0">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="col-md-12 text-center custom-menu mt-3 mb-3">
                      <h2>
                        <JTranslation text={selectedMenu?.menuName!} />
                      </h2>
                    </div>
                    {selectedMenu?.signedUrl && (
                      <div className="custom-menu-banner pt-3">
                        <img
                          src={selectedMenu?.signedUrl}
                          alt={selectedMenu?.menuName.toLowerCase()}
                        />
                      </div>
                    )}
                    <div className="custom-menu-content mt-4 text-center">
                      <p>
                        {' '}
                        <JTranslation text={unescapeHtml(selectedMenu?.menuDescription ?? '')} type="html" />
                      </p>
                    </div>

                    <div className="splitter mt-5">
                      <hr></hr>
                    </div>

                    <div className="mt-5">
                      {/* <!---------category and items-----> */}

                      {selectedMenu?.subCategory
                        ?.filter((sub) => sub.isActive && sub.menuItems.length)
                        .map((sub, index) => {
                          return (
                            <div
                              className="row mt-3"
                              key={sub.id}
                              id={`${getValueAfterHash(sub.id)}`}
                            >
                              <div
                                className="col-md-12 text-center  mb-2"
                                id={'scroll#' + index}
                              >
                                <h3 className="category-title-main mb-0">
                                  <JTranslation text={sub.categoryName} />
                                </h3>
                              </div>

                              {sub.categoryDescription && (
                                <div className="d-flex align-items-center justify-content-center text-center mb-4">
                                  <div className="col-md-10 col-lg-8">
                                    <p className="category-additional-info mb-3">
                                      <JTranslation
                                        text={sub.categoryDescription}
                                        type="html"
                                      />
                                    </p>
                                  </div>
                                </div>
                              )}

                              {sub.menuItems
                                .filter((item) => item.isActive)
                                .map((item) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'
                                      onClick={() => {
                                        saveMenuSelection(
                                          queryClient,
                                          selectedMenu
                                        )
                                        navigate(
                                          `${routes.item_view_special_menu
                                          }/${getValueAfterHash(item.id!)}`,
                                          {
                                            state: {
                                              item: { ...sub },
                                              menu: { ...selectedMenu },
                                            },
                                          }
                                        )
                                        
                                        findMenuAndCategoryName({ menuItemId: item.id, selectedMenu: userSelectedMenu, isCustomMenu: true })
                                          .then(result => {
                                            if (result) {
                                              const { categoryName, menuName } = result
                                              const { itemName, itemPrices } = item
                                              const price = getDefaultPrice(itemPrices).map((item) => (item === 'Infinity' ? null : `$${item}`))                                              
                                              // Google Analytics
                                              trackEvent({
                                                eventName: 'custom_menu_select_menu_item',
                                                label: 'Menu item selection on custom menu guest view page',
                                                value: {
                                                  page_path: '/item-view-special-menu/',
                                                  page_location: `${getDomainFromURL()}/item-view-special-menu/`,
                                                  menu_name: menuName,
                                                  menu_category: categoryName,
                                                  menu_item_name: itemName,
                                                  menu_item_price: price,
                                                }
                                              });
                                            }
                                          });
                                      }}
                                    >
                                      <div className='item-list-main shadow-custom p-2'>
                                        <div className='item-list-image me-2 col-4  p-0'>
                                          <img
                                            src={
                                              (item.signedPhotoKeys &&
                                                item?.signedPhotoKeys[0]) ||
                                              placeholderImage
                                            }
                                            className=''
                                            alt=''
                                          />
                                        </div>

                                        <div className='item-list-content col-8 h-100 p-2'>
                                          <div className='h-100 d-flex flex-column'>
                                            <div className='flex-grow-1'>
                                              <div className='title mb-2'>
                                                <JTranslation
                                                  text={item.itemName}
                                                />
                                              </div>
                                              {item.itemTag &&
                                                getItemTags(item.itemTag)}
                                              <div className='description mb-3 mt-1'>
                                                <JTranslation
                                                  text={item.itemDescription}
                                                />
                                              </div>
                                            </div>

                                            <div className='flex-grow-0'>
                                              <div className='market-price-text'>
                                                {item.isMarketPrice &&
                                                  guestFnbGlobalSettings.showMenuCardPrice ? (
                                                  <JTranslation
                                                    text={
                                                      guestFnbGlobalSettings.marketPriceLabel
                                                    }
                                                  />
                                                ) : (
                                                  ''
                                                )}
                                              </div>

                                              {item.itemPrices.length > 0 &&
                                                !item.isMarketPrice &&
                                                guestFnbGlobalSettings.showMenuCardPrice &&
                                                getDefaultPrice(item.itemPrices)
                                                  .length
                                                ? getItemPricesAndFieldNames(
                                                  item.itemPrices
                                                )
                                                : ''}

                                              <div className='position-relative d-flex align-items-center justify-content-start'>
                                                <div className=' col-9 not-available-menu'>
                                                  {item.isAvailable ? (
                                                    ''
                                                  ) : (
                                                    <JTranslation
                                                      text={ITEM_NOT_AVAILABLE}
                                                    />
                                                  )}
                                                </div>
                                                <div className='col-3 item-arrow text-end'>
                                                  <i className='ri-arrow-right-circle-fill'></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
                </div>
               
              </div>
            </div>
          </>
        ) : (
          <ErrorPage title={specialMenuName} bodyText="The link has been expired!" />
        )
      }
    </>
  )
}

export default withPublicHeader(SpecialMenuGuestView);