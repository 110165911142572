import { AlertVariant, PageMode, PermissionKeys, SubPermissionKeys } from '../../constants/constants'
import {
	AddAvailabilityFormData,
	AddAvailabilityResponse,
	AvailabilityData,
	AvailabilityFormData,
	AvailabilityWeekData,
	AvailabilityWeekFormData,
	OffDays,
	ToastMessageProps,
	UpdateAvailabilityFormData,
} from '../../constants/staticTypes'
import { APPLY, CANCEL, UPDATE } from '../../constants/strings'
import { Field, Form, Formik, FieldProps, FieldArray } from 'formik'
import { Accordion } from 'react-bootstrap'
import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import {
	addStaffAvailability,
	availabilitySchema,
	getOffDays,
	initialValuesForAvailability,
	updateStaffAvailability,
} from '../../helpers/availabilityHelper'
import {
	checkPermission,
	customDeepClone,
	getAllDays,
	getAllWeekDays,
	getStaffId,
	toPascalCase,
} from '../../helpers/utils'
import { WeekPickerProps } from 'antd/es/date-picker'
import AvailabilityTimeForm from './AvailabilityTimeForm'
import useMutationHook from '../../hooks/useMutationHook'
import { useQueryClient } from 'react-query'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { AVAILABILITY_LIST, OFF_DAYS } from '../../constants/queryKeys'
import { AxiosResponse } from 'axios'
import useQueryHook from '../../hooks/useQueryHook'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'

type Props = {
	onClose: Function
	pageMode: PageMode.ADD | PageMode.EDIT
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	availabilityType: 'REGULAR' | 'TEMPORARY'
	selectedUserId?: string | null
}

function AvailabilityForm({ onClose, pageMode, setToastMessage, availabilityType, selectedUserId }: Readonly<Props>) {
	const queryClient = useQueryClient()
	const availabilityMutation = useMutationHook(queryClient, true)
	// const staffId = getStaffId()
	const submitButtonName = pageMode === PageMode.EDIT ? UPDATE : APPLY
	const clonedInitialValues = customDeepClone(initialValuesForAvailability) as AvailabilityFormData

	// CONTEXT VARIABLE
	const { setShowSideBar } = useContext(CommonCtx)
	const { availabilityData, setAvailabilityData } = useContext(ScheduleManagementCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	// STATE VARIABLES
	const [disabledDates, setDisabledDates] = useState<dayjs.Dayjs[]>([])
	const [fromTime, setFromTime] = useState<dayjs.Dayjs | null>(null)
	const [toTime, setToTime] = useState<dayjs.Dayjs | null>(null)
	const [editTimeIndex, setEditTimeIndex] = useState<number | null>(null)
	const [isLoaded, setIsLoaded] = useState<boolean>(false)
	const [mandatoryDays, setMandatoryDays] = useState<dayjs.Dayjs[]>([])
	const [translationText, setTranslatedText] = useState<{
		reason: string,
		leaveMessage1: string,
		leaveMessage2: string,
	  }>({ 
		reason: "Reason/Comments",
		leaveMessage1: "Today, you are on leave",
		leaveMessage2: ", there's no requirement to indicate you availability for this day.",
	});
	const [activeIndex, setActiveIndex] = useState<string[] | null>(null)

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: "Reason/Comments", typeCase: 'pascal', translationContext }),
					jTranslationText({ text: "Today, you are on leave", typeCase: 'pascal', translationContext }),
					jTranslationText({ text: ", there's no requirement to indicate you availability for this day.", typeCase: 'pascal', translationContext }),
				])
				
				setTranslatedText({
					reason: translations[0] ?? "Reason/Comments",
					leaveMessage1: translations[1] ?? "Today, you are on leave",
					leaveMessage2: translations[2] ?? ", there's no requirement to indicate you availability for this day.",
				})
			} catch {
				setTranslatedText({
					reason: "Reason/Comments",
					leaveMessage1: "Today, you are on leave",
					leaveMessage2: ", there's no requirement to indicate you availability for this day.",
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	useEffect(() => {
		const availabilityListResponse = queryClient.getQueryData(AVAILABILITY_LIST) as AxiosResponse<any, any>
		const availabilityListData = availabilityListResponse?.data?.data?.lists ?? [] as AvailabilityData[]

		if (availabilityType === 'TEMPORARY') {
			let disabledWeekDates: dayjs.Dayjs[] = []
			for (const availability of availabilityListData) {
				if (['PENDING', 'APPROVED'].includes(availability.actionStatus) && !availability.isRecurring) {
					const currentStartDate = dayjs(availability.startDate).endOf('day')
					const currentWeekDates = getAllWeekDays(currentStartDate)
					disabledWeekDates = disabledWeekDates.concat(currentWeekDates)
				}
			}
			setDisabledDates(disabledWeekDates)
		}

		const isRecurring = availabilityType === 'TEMPORARY' ? 'temporary' : 'recurring'
		if (pageMode === PageMode.EDIT) {
			setAvailabilityData({ ...clonedInitialValues, ...availabilityData, isRecurring })
		} else {
			setAvailabilityData({ ...clonedInitialValues, staffId: selectedUserId ? selectedUserId : '', isRecurring })
		}
		return () => {
			setAvailabilityData(clonedInitialValues)
			setFromTime(null)
			setToTime(null)
		}
	}, [])

	useEffect(() => {
		if (
			pageMode === PageMode.ADD &&
			disabledDates.some((date) => date.isSame(clonedInitialValues.startDate, 'day'))
		) {
			setAvailabilityData({
				...clonedInitialValues,
				startDate: null,
				endDate: null,
				weekdays: [],
				isRecurring: 'temporary',
				staffId: selectedUserId ? selectedUserId : '',
			})
		}
	}, [disabledDates])

	const changeDateRange = (selectedDate: Dayjs | null, dateString:  string | string[]) => {
		if (selectedDate) {
			const selectedWeekDays = getAllWeekDays(selectedDate)
			setAvailabilityData({
				...availabilityData,
				startDate: selectedWeekDays[0],
				endDate: selectedWeekDays[6],
				weekdays: selectedWeekDays.map((day: Dayjs) => {
					const availabilityWeekData: AvailabilityWeekFormData = {
						weekday: day,
						isAllDay: true,
						isAvailable: true,
						availableTimes: [],
						notes: '',
					}
					return availabilityWeekData
				}),
			})
		} else {
			setAvailabilityData({
				...availabilityData,
				startDate: clonedInitialValues.startDate,
				endDate: clonedInitialValues.endDate,
			})
		}
	}

	const disabledDate: WeekPickerProps['disabledDate'] = (current) => {
		// Can not select days before today and today and selected weeks
		return (
			current &&
			(current < dayjs(clonedInitialValues.startDate).endOf('day') ||
				disabledDates.some((date) => date.isSame(current, 'day')))
		)
	}

	const validate = (values: AvailabilityFormData) => {
		setAvailabilityData((prevValues) => {
			return {
				...prevValues,
				recurring: values.isRecurring,
				startDate: values.startDate,
				endDate: values.isRecurring === 'recurring' ? null : values.endDate,
				staffId: values.staffId,
				weekdays: values.weekdays.map((day: AvailabilityWeekFormData) => {
					const weekdayData: AvailabilityWeekFormData = {
						isAllDay: day.isAllDay,
						isAvailable: day.isAvailable,
						weekday: day.weekday,
						notes: day.notes ?? '',
						availableTimes: [],
					}
					if (!day.isAllDay && day.isAvailable) {
						weekdayData.availableTimes = day.availableTimes.map((time) => ({
							from: time.from,
							to: time.to,
						}))
					}
					return weekdayData
				}),
			}
		})
	}

	const onSuccess = (res: AddAvailabilityResponse) => {
		queryClient.refetchQueries([AVAILABILITY_LIST])
		setShowSideBar(false)
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const handleSubmit = (values: AvailabilityFormData) => {
		const { isRecurring, startDate, endDate, staffId, weekdays } = values
		const params: AddAvailabilityFormData = {
			isRecurring: isRecurring === 'recurring',
			startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
			endDate: isRecurring === 'recurring' ? null : endDate?.format('YYYY-MM-DD') ?? null,
			staffId: staffId,
			weekdays: weekdays.map((day: AvailabilityWeekFormData) => {
				const weekdayData: AvailabilityWeekData = {
					isAllDay: day.isAllDay,
					isAvailable: day.isAvailable,
					weekday: day.weekday.format('dddd').toLowerCase(),
				}
				if (!day.isAllDay && day.isAvailable) {
					weekdayData.availableTimes = day.availableTimes.map((time) => ({
						from: time.from.format('hh:mm a'),
						to: time.to.format('hh:mm a'),
					}))
				}
				if (day.notes) {
					weekdayData.notes = day.notes
				}
				return weekdayData
			}),
		}

		if (pageMode === PageMode.ADD) {
			addStaffAvailability(availabilityMutation, params, onSuccess, onError)
		} else {
			const updatedParams: UpdateAvailabilityFormData = {
				...params,
				id: availabilityData.id,
				staffId: availabilityData.staffId,
			}
			updateStaffAvailability(availabilityMutation, updatedParams, onSuccess, onError)
		}
	}

	const updateOffDays = (offDays: OffDays) => {
		const clonedData: AvailabilityFormData = customDeepClone(availabilityData) as AvailabilityFormData

		// get all mandatory days
		let currentAllMandatoryDays: dayjs.Dayjs[] = []
		for (const day of offDays.mandatoryDays) {
			const allMandatoryDays = dayjs(day.startDate).isSame(dayjs(day.endDate))
				? [dayjs(day.startDate)]
				: getAllDays(dayjs(day.startDate), dayjs(day.endDate))

			for (const date of allMandatoryDays) {
				// Check if the date is not already in the array
				if (!currentAllMandatoryDays.some((existingDate) => existingDate.isSame(date))) {
					currentAllMandatoryDays.push(date)
				}
			}
		}
		setMandatoryDays(currentAllMandatoryDays)

		const updatedAvailabilityData = clonedData.weekdays.map((data) => {
			const updatedData: AvailabilityWeekFormData = data

			// check if days are in holidays
			const checkHoliday = offDays.holidays.find(
				(date) => dayjs(date.startDate).isSame(dayjs(updatedData.weekday)) && date.actionStatus === 'PUBLISHED'
			)
			if (checkHoliday) {
				updatedData.isAvailable = false
				updatedData.isAllDay = false
				updatedData.reason = `${toPascalCase(
					checkHoliday.title
				)}`
			}

			// check if days are in time-off
			const checkTimeOff = offDays.timeOffs.find(
				(date) => dayjs(date.startDate).isSame(dayjs(updatedData.weekday)) && date.actionStatus === 'APPROVED'
			)
			if (checkTimeOff) {
				updatedData.isAvailable = false
				updatedData.isAllDay = false
				updatedData.reason = `${translationText.leaveMessage1} ${
					checkTimeOff?.reason && '(' + toPascalCase(checkTimeOff?.reason) + ')'
				}${translationText.leaveMessage2}`
			}

			return updatedData
		})

		setAvailabilityData((prevValues) => {
			return {
				...prevValues,
				weekdays: updatedAvailabilityData,
			}
		})
	}

	useEffect(() => {
		if (isLoaded && availabilityData.isRecurring === 'temporary') {
			getOffDays({
				startDate: availabilityData.startDate?.format('YYYY-MM-DD') as string,
				endDate: availabilityData.endDate?.format('YYYY-MM-DD') as string,
				staffId: availabilityData.staffId,
			}).then((res: AxiosResponse<any, any>) => {
				const offDays = res.data.data as OffDays
				updateOffDays(offDays)
			})
		}
	}, [availabilityData.startDate, availabilityData.endDate])

	const offDaysData = useQueryHook(
		OFF_DAYS,
		() =>
			getOffDays({
				startDate: availabilityData.startDate?.format('YYYY-MM-DD') as string,
				endDate: availabilityData.endDate?.format('YYYY-MM-DD') as string,
				staffId: availabilityData.staffId,
			}),
		(res: AxiosResponse<any, any>) => {
			if (availabilityData.isRecurring === 'recurring') {
				return
			}
			const offDays = res.data.data as OffDays
			updateOffDays(offDays)
			setIsLoaded(true)
		}
	)

	return (
		<Formik
			enableReinitialize={true}
			initialValues={availabilityData}
			onSubmit={handleSubmit}
			validate={validate}
			validateOnChange={true}
			validationSchema={availabilitySchema}
		>
			{(formikProps) => {
				const weekdaysErrors = formikProps.errors?.weekdays
				const hasAvailabilityValidationErrors =
					weekdaysErrors &&
					(Array.isArray(weekdaysErrors)
						? weekdaysErrors.map((day: any) => day?.availableTimes ?? null)
						: null)
				return (
					<Form>
						{availabilityType === 'TEMPORARY' && (
							<div className="mb-3">
								<div className="row">
									<div className="col-md-12">
										<div className="help-small">
											<label className="form-label d-flex">
												<JTranslation typeCase="pascal" text={"Date Range"} /><span className="mandatory ">*</span>
											</label>
										</div>
										<DatePicker
											data-test-id={'availability-range-picker'}
											disabledDate={disabledDate}
											className="custom-week-picker"
											onChange={changeDateRange}
											picker="week"
											format={(value) =>
												`${dayjs(value).startOf('week').format('MM/DD/YYYY')} - ${dayjs(value)
													.endOf('week')
													.format('MM/DD/YYYY')}`
											}
											value={availabilityData.startDate && dayjs(availabilityData.startDate)}
											disabled={pageMode === PageMode.EDIT}
											allowClear={false}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						)}

						<div className="mt-4 mb-3">
							<Accordion className="availability-form-accordion" activeKey={activeIndex} onSelect={(eventKey) => {
								setActiveIndex((prev) => {
									if(eventKey) {
										if (prev) {
											const ifAlreadyExisted = prev.includes(eventKey.toString())
											if(ifAlreadyExisted) {
												return prev.filter((index) => index !== eventKey.toString())
											}
											return [...prev, eventKey.toString()];
										}
										return [eventKey.toString()];
									}
									return prev
								})
							}}>
								<FieldArray
									name="availability"
									render={(arrayHelpers) => {
										return (
											<div className="flex-grow-1">
												{formikProps.values.weekdays.map((data, index) => (
													<Accordion.Item key={index} eventKey={index.toString()}>
														<Accordion.Header>
															<div className="availability-header">
																<div
																	className={`availability-title ${
																		hasAvailabilityValidationErrors?.[index]
																			? 'text-danger'
																			: ''
																	}`}
																>
																	{data?.weekday && availabilityType === 'TEMPORARY'
																		? dayjs(data.weekday).format(
																				'MM/DD/YYYY - dddd'
																		)
																		// : checkPermission(
																		// 		queryClient,
																		// 		PermissionKeys.MANAGE_AVAILABILITY,
																		// 		SubPermissionKeys.MANAGE_REQUEST
																		// )
																		// ? dayjs(data.weekday).format(
																		// 		'MM/DD/YYYY - dddd'
																		// )
																		: dayjs(data.weekday).format('dddd')}
																</div>
																<div className="availability-input-switch">
																	<div className="form-check form-switch d-flex justify-content-between align-items-end">
																		<Field name={`weekdays.${index}.isAllDay`}>
																			{({ field, form }: FieldProps) => (
																				<input
																					disabled={
																						data.reason ? true : false
																					}
																					data-test-id={`availability-weekdays-${index}-all-day-checkbox`}
																					className="form-check-input"
																					autoComplete="off"
																					type="checkbox"
																					role="switch"
																					id="flexSwitchCheckDefault"
																					checked={data.isAllDay}
																					onChange={(event) => {
																						form.setFieldValue(
																							field.name,
																							event.target.checked
																						)

																						// Added time-out for solving UI race condition
																						setTimeout(() => {
																							if (event.target.checked) {
																								form.setFieldValue(
																									`weekdays.${index}.isAvailable`,
																									true
																								)
																								setActiveIndex((prev) => {
																									if (prev) {
																										return prev.filter((i) => i !== index.toString());
																									}
																									return [];
																								});
																							} else {
																								setActiveIndex((prev) => {
																									if (prev) {
																										return [...prev, index.toString()];
																									}
																									return [index.toString()];
																								});
																							}
																						}, 0)
																					}}
																					onClick={(e) => e.stopPropagation()}
																				/>
																			)}
																		</Field>
																		<label className="form-check-label ps-2">
																			<JTranslation typeCase="pascal" text={"All Day"} />
																		</label>
																	</div>
																</div>
															</div>
														</Accordion.Header>
														<Accordion.Body >
															{data.reason ? (
																<span className="text-danger">{data.reason}</span>
															) : (
																<>
																	<div className="mb-2 p-2 custom-group-box">
																		<div className="form-check form-switch d-flex justify-content-between align-items-end">
																			<div className="d-flex align-items-center">
																				<Field
																					name={`weekdays.${index}.isAvailable`}
																				>
																					{({ field, form }: FieldProps) => (
																						<input
																							data-test-id={`availability-weekdays-${index}-available-checkbox`}
																							checked={!data.isAvailable}
																							className="form-check-input"
																							autoComplete="off"
																							type="checkbox"
																							role="switch"
																							id="flexSwitchCheckDefault"
																							onChange={(event) => {
																								form.setFieldValue(
																									field.name,
																									!event.target
																										.checked
																								)

																								// Added time-out for solving UI race condition
																								setTimeout(() => {
																									if (
																										event.target
																											.checked
																									) {
																										form.setFieldValue(
																											`weekdays.${index}.isAllDay`,
																											false
																										)
																									}
																								}, 0)
																							}}
																							onClick={(e) =>
																								e.stopPropagation()
																							}
																						/>
																					)}
																				</Field>
																				<label className="form-check-label ps-2 mt-1">
																					<JTranslation typeCase="pascal" text={"Not Available"} />
																				</label>
																			</div>

																			{mandatoryDays.some((date) =>
																				date.isSame(data.weekday)
																			) && (
																				<span className="text-success">
																					<JTranslation typeCase="pascal" text={"Mandatory Day"} />
																				</span>
																			)}
																		</div>
																	</div>

																	<AvailabilityTimeForm
																		weekdayIndex={index}
																		availabilityData={availabilityData}
																		availabilityWeekData={data}
																		fromTime={fromTime}
																		toTime={toTime}
																		setFromTime={setFromTime}
																		setToTime={setToTime}
																		editTimeIndex={editTimeIndex}
																		setEditTimeIndex={setEditTimeIndex}
																		formikProps={formikProps}
																	/>

																	{hasAvailabilityValidationErrors?.[index] && (
																		<span className="formik-error">
																			<JTranslation typeCase="pascal" text={hasAvailabilityValidationErrors?.[index]} />
																		</span>
																	)}

																	<div className="availability-reason">
																		<Field
																			data-test-id={`availability-weekdays-${index}-notes-input`}
																			name={`weekdays.${index}.notes`}
																			className="form-control"
																			type="text"
																			autoComplete="off"
																			placeholder={translationText.reason}
																			value={data.notes}
																		/>
																	</div>
																</>
															)}
														</Accordion.Body>
													</Accordion.Item>
												))}
											</div>
										)
									}}
								/>
							</Accordion>
						</div>

						{/* action button */}
						<div className="save-btn-section shadow save-btn-absolute">
							<button className="btn btn-custom-primary-outline" type="reset" onClick={() => onClose()}>
								<JTranslation typeCase="pascal" text={CANCEL} />
							</button>

							<button
								className="btn btn-custom-primary"
								type="submit"
								disabled={offDaysData.isFetching || offDaysData.isRefetching ? true : false}
							>
								{offDaysData.isFetching || offDaysData.isRefetching 
									? <JTranslation typeCase="pascal" text={'loading...'} /> 
									: <JTranslation typeCase="pascal" text={submitButtonName} />
								}
							</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default AvailabilityForm
