import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Badge } from 'primereact/badge';
import { calculateDuration, capitalizeFirstLetter, convertUTCtoLocalByOffset, getStaffId } from '../../helpers/utils';
import { Card } from 'primereact/card';
import { TimeOffEntry } from '../../constants/staticTypes';
import { DataView } from 'primereact/dataview';
import { getAllByUser } from '../../helpers/timeOffHelper';
import { JTranslation } from '../../helpers/jTranslate';
import { MORE_INFO } from '../../constants/strings';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from 'react-router-dom';
import { USERS_TIME_OFF_LIST } from '../../constants/queryKeys';
import dayjs from 'dayjs';
import MlxPopover from '../common/MlxPopover';
import useQueryHook from '../../hooks/useQueryHook';

function TimeOffCard() {
	const [timeOffRequestList, setTimeOffRequestList] = useState<TimeOffEntry[]>([])
	const [pendingRequests, setPendingRequests] = useState<number>(0)
	const staffId = getStaffId()
	const navigate = useNavigate()

	// users list fetch success
	const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const timeOffRequestListData = res.data.data.lists || [];
		const filteredData = timeOffRequestListData.filter((item: any) => {
			const today = new Date();
			const startDate = new Date(item.startDate);
			const isStartDateFromToday = startDate >= today;
			const isStatusValid = item.actionStatus !== 'REJECTED' && item.actionStatus !== 'CANCELLED';
			return isStartDateFromToday && isStatusValid;
		});
		const sortedTimeOffRequestListData = (filteredData as TimeOffEntry[]).sort((a, b) => b.createdAt - a.createdAt);
		setPendingRequests(sortedTimeOffRequestListData.length - 5);
		setTimeOffRequestList(sortedTimeOffRequestListData.slice(0, 5));
	}

	// fetch users list
	const timeOffData = useQueryHook(
		USERS_TIME_OFF_LIST,
		() => {
			return getAllByUser({ id: staffId, limit: 500 });
		},
		onUserListFetchSuccess
	);


	const parseDuration = (timeOff: TimeOffEntry) => {
		const { type, startDate, startTime, endTime, endDate } = timeOff
		// Get today's date
		const today = dayjs()

		// Parse the start and end times
		const startTimeParsed = dayjs(`2000-01-01 ${startTime}`, { format: 'YYYY-MM-DD HH:mm:ss' })
		const endTimeParsed = dayjs(`2000-01-01 ${endTime}`, { format: 'YYYY-MM-DD HH:mm:ss' })

		// Combine today's date with the start time
		const start = today
			.set('hour', startTimeParsed.hour())
			.set('minute', startTimeParsed.minute())
			.set('second', startTimeParsed.second())

		// Combine today's date with the end time
		const end = today
			.set('hour', endTimeParsed.hour())
			.set('minute', endTimeParsed.minute())
			.set('second', endTimeParsed.second())

		return calculateDuration({
			startTime: dayjs(start),
			endTime: dayjs(end),
			fromDate: startDate,
			toDate: endDate,
			type: type,
		})
	}

	const durationTemplate = (timeOff: TimeOffEntry) => {
		const duration = parseDuration(timeOff)
		if (timeOff.type === 'time-off') {
			return (
				<>
					{' '}
					<div className="d-flex">
						<div className="flex-grow-0">
							<span>{duration}</span>
						</div>
						<div className="flex-grow-0">
							<OverlayTrigger
								trigger={["hover", "hover"]}
								placement="auto"
								overlay={
									<Tooltip className="addon-tooltip">
										{dayjs(`${timeOff.startDate} ${timeOff.startTime}`)
											.format('hh:mm A')
											.toString()}{' '}
										- {dayjs(`${timeOff.endDate} ${timeOff.endTime}`).format('hh:mm A').toString()}
									</Tooltip>
								}
							>
								<span className="table-help-main ms-1">
									<i className="ri-time-line"></i>
								</span>
							</OverlayTrigger>
						</div>
					</div>
				</>
			)
		}
		return <span>{duration}</span>
	}


	const moreInfoTemplate = (rowData: any) => {
		let noteElement = null
		if (['REJECTED', 'CANCELLED'].includes(rowData.actionStatus)) {
			noteElement = (
				<div className="popover-time-off-more-info">
					<p className="more-info">
						<JTranslation typeCase="pascal" text={rowData.actionStatus} /> on {dayjs(rowData.updatedAt).format('MM/DD/YYYY')} at{' '}
						{dayjs(rowData.updatedAt).format('hh:mm a')}
					</p>
					{rowData.note && (
						<div className="notes">
							<span>
								<b><JTranslation typeCase="pascal" text={"Notes"} /></b>
							</span>
							<br />
							<span>{rowData.note}</span>
						</div>
					)}
				</div>
			)
		}
		return noteElement
	}

	const timeOffStatusTemplate = (timeOff: TimeOffEntry, note: JSX.Element | null) => {
		const { actionStatus } = timeOff

		switch (actionStatus) {
			case 'PENDING':
				return (
					<>
						<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Pending"} />} style={{ backgroundColor: "rgba(251, 170, 7, 0.25)", color: "#fbaa07" }}></Badge>
					</>
				)
			case 'APPROVED':
				return (
					<>
						<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Approved"} />} severity="success"></Badge>
					</>
				)

			case 'CANCELLED':
				return (
					<div
						className={`w-100 d-flex justify-content-even flex-row-reverse align-items-center`}
					>
						{note && (
							<MlxPopover data={{ title: MORE_INFO, body: note }} />

						)}
						<Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={"Cancelled"} />} severity="warning"></Badge>
					</div>
				)

			case 'REJECTED':
				return (
					<div
						className={`w-100 d-flex justify-content-even flex-row-reverse align-items-center`}
					>
						{note && (
							<MlxPopover data={{ title: MORE_INFO, body: note }} />
						)}
						<Badge className="me-1 custom-badge" value={<JTranslation typeCase="pascal" text={"Rejected"} />} severity="danger"></Badge>
					</div>
				)

			default:
				return null
		}
	}

	const itemTemplate = (data: TimeOffEntry) => {
		return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
				<div className="view-header d-flex flex-row justify-content-between align-items-center">
					{data?.reason && (<p className="m-0">{capitalizeFirstLetter(data.reason)}</p>)}
					<p className="m-0">{durationTemplate(data)}</p>
				</div>
				<div className='mt-2'>
					<p className="m-0">
						<JTranslation typeCase="pascal" text={"From Date"} />: <span className="font-bold">{convertUTCtoLocalByOffset(data.startDate)}</span>
					</p>
					<p className="m-0">
						<JTranslation typeCase="pascal" text={"To Date"} />: <span className="font-bold">{convertUTCtoLocalByOffset(data.endDate)}</span>
					</p>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-end align-items-center">
					{timeOffStatusTemplate(data, moreInfoTemplate(data))}
				</div>
			</Card>
		)
	}

	return (
		<>
			<Card
				className="dashboard-timeoff-card card h-100 custom-shadow border-radius-05 border-0"
				title={
					<div className='row'>
						<div className='d-flex justify-content-between'>
							<div className='d-flex align-items-center'>
								<JTranslation typeCase="pascal" text={"My Time Off"} />
								{pendingRequests > 0 && <Badge className='ms-2 dashboard-badge' value={`+ ${pendingRequests}`} severity="danger"></Badge>}
							</div>
							<button className="btn btn-custom-primary dashboard-btn" onClick={() => navigate('/time-off')}><JTranslation typeCase="pascal" text={'View All'} /></button>
						</div>
					</div>
				}
			>
				<div className='dashboard-widget-height'>
					{timeOffData.isFetching ?
						<>
							<Skeleton className='mb-1' height='4rem'></Skeleton>
							<Skeleton className='mb-1' height='4rem'></Skeleton>
							<Skeleton className='mb-1' height='4rem'></Skeleton>
						</>
						:
						timeOffRequestList.length > 0 ?
							<DataView
								value={timeOffRequestList}
								layout={'list'}
								itemTemplate={itemTemplate}
								// paginator
								rows={5}
								paginatorClassName="p-dataview-timeoff"
								// paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
								// rowsPerPageOptions={[10, 25, 50]}
								dataKey="id"
								emptyMessage={"No Requests Found"}
							// currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
							/>
							:
							<div><JTranslation typeCase="pascal" text={'No Upcoming Time Offs'} /></div>
					}
				</div>
			</Card>
		</>
	)
}

export default TimeOffCard