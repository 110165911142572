import { useCallback, useEffect, useState } from "react";
import ToastAlert from "../../components/alert/ToastAlert";
import MlxPopover from "../../components/common/MlxPopover";
import { JTranslation } from "../../helpers/jTranslate";
import withSidebar from "../../hoc/withSidebar";
import { ToastMessageProps } from "../../constants/staticTypes";
import { toastMessageInitialData } from "../../constants/constants";
import { TabPanel, TabView } from "primereact/tabview";
import { routes } from "../../constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import SwapScheduleView from "../../components/shift_swap/SwapScheduleView";
import './shift_swap.scss'
import SwapRequestDataListGrid from "../../components/shift_swap/SwapRequestDataListGrid";
import { fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, getWeekRange, ScheduleData, ScheduleIdsResponse, ScheduleMetadata, StartAndEndDates } from "../../helpers/workTimeScheduleHelper";
import { AxiosResponse } from "axios";
import { isEmptyObject } from "jquery";
import { SCHEDULE_STATUS, STAFFS_WITH_SCHEDULES_AND_SHIFT } from "../../constants/queryKeys";
import useQueryHook from "../../hooks/useQueryHook";
import dayjs from "dayjs";

function ShiftSwap() {
    const navigate = useNavigate()
    const location = useLocation();
    const queryClient = useQueryClient()
    const tabRoutes = [routes.shift_swap, routes.swap_requests]

    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [activeIndex, setActiveIndex] = useState(0)
    const [scheduleMetadata, setScheduleMetadata] = useState<ScheduleMetadata | null>(null)
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [selectedDateRange, setSelectedDateRange] = useState<StartAndEndDates>(getWeekRange(selectedWeek))

    // fetch add-on/add-on group based on tab change
    const refetchQueriesBasedOnCurrentUrlPath = useCallback(() => {
        const currentPath = location.pathname;
        if (currentPath === routes.shift_swap) {
            if (publishedScheduleId) {
                staffsWithScheduleQuery.refetch()
            }
            setActiveIndex(0);
        }
        if (currentPath === routes.swap_requests) {
            // queryClient.refetchQueries([ADD_ONS_GROUP_LIST])
            setActiveIndex(1)
        }
    }, [location.pathname, queryClient]); // checked pathname, since calling same URL, location has diff key


    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        const { draft, published } = scheduleData

        // Check if draft and published schedules are empty
        const isDraftEmpty = isEmptyObject(draft)
        const isPublishedEmpty = isEmptyObject(published)

        // If published is not empty, save the published's id using savePublishedScheduleId
        // if (!isPublishedEmpty && isDraftEmpty) {
        if (!isPublishedEmpty) {
            setPublishedScheduleId(published.id)
        } else {
            setPublishedScheduleId("")
        }
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const staffsWithSchedule: ScheduleData = res.data.data
        setStaffsWithSchedule(staffsWithSchedule)
        setScheduleMetadata(staffsWithSchedule?.schedule?.scheduleMetadata ?? {} as ScheduleMetadata)
    }

    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(
        SCHEDULE_STATUS,
        () => fetchDraftedAndPublishedScheduleIds(selectedDateRange),
        onSuccessOfScheduleStatus,
        (error) => console.log(`Error fetching draft and published schedule ids.`, error),
        true,
        false,
    )

    // schedule data main QUERY-2
    const staffsWithScheduleQuery = useQueryHook(
        STAFFS_WITH_SCHEDULES_AND_SHIFT,
        () => fetchStaffsWithSchedulesAndShift({ scheduleId: publishedScheduleId }),
        onSuccessOfStaffsWithSchedule,
        undefined,
        !!publishedScheduleId,
        false
    )

    useEffect(() => {
        if (publishedScheduleId) {
            staffsWithScheduleQuery.refetch()
        }
    }, [publishedScheduleId])

    useEffect(() => {
        refetchQueriesBasedOnCurrentUrlPath();
    }, [refetchQueriesBasedOnCurrentUrlPath]);

    return <>
        {/* TOAST MESSAGE COMPONENT */}
        <ToastAlert
            show={toastMessage.show}
            onClose={() => setToastMessage(toastMessageInitialData)}
            message={toastMessage.message}
            variant={toastMessage.variant}
        />

        <div className="col-md-12 col-lg-12 mb-3 h-100 ">
            <div className="page-title d-flex mb-3 align-items-center">
                <div className="flex-grow-1 d-flex">
                    <span className="d-flex">
                        <h5 className="d-flex mb-0 align-items-center">
                            <JTranslation typeCase="pascal" text={'Shift Swap'} />
                            <MlxPopover data={{ title: 'Shift Swap', body: '' }} />
                        </h5>
                    </span>
                </div>
            </div>
            <div className="card h-100 overflow-auto">
                <div className="flex-grow-0 p-0">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 me-3 ms-1 gap-2 d-flex">
                            {/* Tab */}
                            <TabView className="w-100" activeIndex={activeIndex} onTabChange={(e) => {
                                navigate(`${tabRoutes[e.index]}`)
                            }}>
                                <TabPanel header={<JTranslation typeCase="pascal" text={'Schedule'} />}></TabPanel>
                                <TabPanel header={<JTranslation typeCase="pascal" text={'Swap Requests'} />}></TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
                <div className="card-body flex-grow-1 overflow-auto">
                    {/* tab components */}
                    {activeIndex === 0 && (
                        <SwapScheduleView
                            staffsWithSchedule={staffsWithSchedule}
                            staffsWithScheduleQuery={staffsWithScheduleQuery}
                            scheduleDataQuery={scheduleDataQuery}
                            selectedWeek={selectedWeek}
                        />
                    )}
                    {activeIndex === 1 && (
                        <SwapRequestDataListGrid
                            staffsWithSchedule={staffsWithSchedule}
                            staffsWithScheduleQuery={staffsWithScheduleQuery}
                            scheduleDataQuery={scheduleDataQuery}
                            selectedWeek={selectedWeek}
                        />
                    )}
                </div>
            </div>
        </div>
    </>
}

export default withSidebar(ShiftSwap);
