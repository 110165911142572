import { BD, CLOSE, SELECT_SHIFT, SHIFT } from '../../constants/strings'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { ErrorMessage, Field, FieldArrayRenderProps, FieldProps, FormikErrors, FormikTouched } from 'formik'
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch'
import { PrimeReactDropDown, ShiftAssignment } from '../../constants/staticTypes'
import { optionTemplateWithTime, AddShiftButton, shiftSelectionTemplate } from '../../helpers/workTimeScheduleHelper'

type Props = {
    shifts: PrimeReactDropDown[]
    arrayHelpers: FieldArrayRenderProps
    availabilityIndex: number
    roleIndex: number
    shiftIndex: number
    errors: FormikErrors<ShiftAssignment>
    touched: FormikTouched<ShiftAssignment>
}

const ShiftAssignmentCard = ({
    shifts,
    availabilityIndex,
    roleIndex,
    shiftIndex,
    errors,
    touched,
    arrayHelpers,
}: Props) => {

    return (
        <>
            <div className="d-flex gap-2 align-items-center mb-2 flex-wrap">
                <div className="flex-grow-1">
                    <strong>{SHIFT}</strong>
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}`}>
                        {({ field, form }: FieldProps) => {
                            const { code, name, startTime, endTime } = field.value
                            //@ts-ignore
                            const rolesExist = errors?.availability?.[availabilityIndex]?.roles?.[roleIndex]?.shifts?.[shiftIndex]
                            const touchedExist = touched?.availability?.[availabilityIndex]?.roles?.[roleIndex]?.shifts?.[shiftIndex]

                            // Check if rolesExist and touchedExist are not undefined
                            const isError = rolesExist && touchedExist

                            // Apply style conditionally
                            const shiftStyle = isError ? { border: '1px solid red' } : undefined
                            return (
                                <Dropdown
                                    value={{ code, name, startTime, endTime }}
                                    onChange={(e: DropdownChangeEvent) => {
                                        const { value } = e
                                        form.setFieldValue(field.name, { ...field.value, ...value })
                                    }}
                                    style={shiftStyle}
                                    options={shifts}
                                    optionLabel="name"
                                    placeholder={SELECT_SHIFT}
                                    disabled={!shifts.length}
                                    valueTemplate={shiftSelectionTemplate}
                                    itemTemplate={optionTemplateWithTime}
                                    className="w-100"
                                />
                            )
                        }}
                    </Field>
                </div>

                {/* ADD BUTTON */}
                <div className="flex-grow-0">{shiftIndex === 0 && AddShiftButton({ arrayHelpers: arrayHelpers })}</div>

                {/* REMOVE BUTTON */}
                {shiftIndex > 0 && (
                    <i className="ri-delete-bin-6-line delete mt-4" onClick={() => arrayHelpers.remove(shiftIndex)}></i>
                )}
                <ErrorMessage
                    className="formik-error visible w-100"
                    name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.code`}
                    component="div"
                />
            </div>
            <div className=" mb-3 pb-2 border-bottom-dashed">
                <div className="d-flex align-items-center">
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.close`}>
                        {({ field, form }: FieldProps) => {
                            return (
                                <>
                                    <InputSwitch
                                        checked={field.value}
                                        onChange={(e: InputSwitchChangeEvent) => {
                                            const { value } = e
                                            form.setFieldValue(field.name, value)
                                        }}
                                    />
                                    <label className="me-3 ms-1">{CLOSE}</label>
                                </>
                            )
                        }}
                    </Field>
                    <ErrorMessage
                        className="formik-error"
                        name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.close`}
                        component="div"
                    />
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.bd`}>
                        {({ field, form }: FieldProps) => {
                            return (
                                <>
                                    <InputSwitch
                                        checked={field.value}
                                        onChange={(e: InputSwitchChangeEvent) => {
                                            const { value } = e
                                            form.setFieldValue(field.name, value)
                                        }}
                                    />
                                    <label className="ms-1">{BD}</label>
                                </>
                            )
                        }}
                    </Field>
                </div>

                <div className="d-block mt-2">
                    <ErrorMessage
                        className="formik-error"
                        name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.bd`}
                        component="div"
                    />
                </div>

            </div>

        </>
    )
}

export default ShiftAssignmentCard
