// api base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const aboutUsConfigApi = "/private/tenants/branding/about_us";
export const aboutUsConfigPublicApi = "/public/tenants/branding/about_us";
export const addOnGroupApi = "/private/tenants/menus/addons_groups/item";
export const addOnGroupSingleApi = "/private/tenants/menus/addons_groups/get";
export const addOnsApi = "/private/tenants/menus/addons/addon";
export const addOnsSingleApi = "/private/tenants/menus/addons/get";
export const aiImageGenerateApi = "/private/app/ai/generate/image";
export const aiTextGenerateApi = "/private/app/ai/generate/text";
export const approveExtractedFileResult = "/private/tenants/file_extract/approve_result";
export const availabilityForManager = "/private/tenants/availability/staff/manager";
export const categorySortApi = "/private/tenants/menus/category/sort";
export const changeExtractedEntity = "/private/tenants/file_extract/change_extracted_entity";
export const changeMarketPriceLabelApi = "/private/tenants/menus/globals/set_market_price_label";
export const cloneAddOnGroupApi = "/private/tenants/menus/addons_groups/copy";
export const couponDetailsApi = "/private/tenants/coupons/details";
export const couponRedeemApi = "/private/tenants/coupons/mark_usage";
export const couponsListApi = "/private/tenants/coupons/list";
export const customMenuAddExistingItemsApi = "/private/tenants/custom_menu/bulk_add_existing_items";
export const customMenuCategoryApi = "/private/tenants/custom_menu/category";
export const customMenuCategoryStatusApi = "/private/tenants/custom_menu/category/status";
export const customMenuChangeMarketPriceLabelApi = "/private/tenants/custom_menu/globals/set_market_price_label";
export const customMenuGuestViewApi = "/public/tenants/custom_menu/menu/code";
export const customMenuItemApi = "/private/tenants/custom_menu/item";
export const customMenuItemDetailsApi = "/private/tenants/custom_menu/item/details";
export const customMenuItemDetailsViewApi = "/public/tenants/custom_menu/item/get";
export const customMenuItemStatusApi = "/private/tenants/custom_menu/status";
export const customMenusApi = "/private/tenants/custom_menu/menu";
export const customMenuSortCategoryApi = "/private/tenants/custom_menu/category/sort";
export const customMenuSortItemApi = "/private/tenants/custom_menu/sort";
export const customMenuStatusApi = "/private/tenants/custom_menu/menu_status";
export const customMenuToggleCardPriceApi = "/private/tenants/custom_menu/globals/toggle_card_price";
export const customMenuToggleSuggestedCardPriceApi = "/private/tenants/custom_menu/globals/toggle_suggested_card_price";
export const eventApi = "/private/tenants/posts/event";
export const eventListGuestViewApi = "/public/tenants/posts/list/event";
export const fetchNextOccurrence = "/private/tenants/posts/publish_date";
export const fileManagementCreateOrDeleteFolder = "/private/tenants/file_management/folder";
export const fileManagementDeleteFile = "/private/tenants/file_management/file_delete";
export const fileManagementDeleteMultiple = "/private/tenants/file_management/delete_bulk";
export const fileManagementDownloadAsZip = "/private/tenants/file_management_archive/zip";
export const fileManagementMoveFile = "/private/tenants/file_management/move/file";
export const fileManagementMoveFolder = "/private/tenants/file_management/move/folder";
export const fileManagementRenameFile = "/private/tenants/file_management/file_rename";
export const fileManagementRenameFolder = "/private/tenants/file_management/rename";
export const fileManagementSearch = "/private/tenants/file_management/search";
export const fileUploadApi = "/private/files/tenants/s3/image/upload";
export const forgotPasswordApi = "/public/tenant_auth/forgot_password";
export const gameManagementApi = "/private/tenants/games/spinTheWheel/prize_option";
export const gameStatusChange = "/private/tenants/games/spinTheWheel/change_status";
export const gameUpdateBetterLuckApi = "/private/tenants/games/spinTheWheel/prize_option_no_price";
export const getExtractedFiles = "/private/tenants/file_extract/list_all";
export const getFileTypes = "/private/tenants/file_extract/defaults/document_types";
export const getFileUploadUrlApi = "/private/tenants/file_management/get_post_upload_url";
export const getFnBGlobalSettingsApi = "/private/tenants/menus/globals";
export const getShortCodeToUrlApi = "/public/tenant_auth/get_url";
export const getSingleCustomMenuApi = "/private/tenants/custom_menu/menu/get";
export const getSingleCustomMenuGuestApi = "/public/tenants/custom_menu/menu/get";
export const getUploadUrlApi = "/private/files/tenants/s3/get_url_post";
export const guestConfirmPrizeApi = "/public/tenants/games/spinTheWheel/confirm_win";
export const guestGameOptionsListApi = "/public/tenants/games/spinTheWheel/prize_option";
export const guestListApi = "/private/tenants/guests/list";
export const guestPrizeApi = "/public/tenants/games/spinTheWheel/get_probabilities";
export const guestSurveyRatingGraph = "/private/tenants/dashboard/reports/survey_feedback";
export const guestVisitedData = "/private/tenants/dashboard/reports/guest_frequent_visits";
export const guestVisitedGraph = "/private/tenants/dashboard/reports/guest_visits";
export const holidayApi = "/private/tenants/holidays";
export const imageDataFetchPublicApi = "/public/tenants/branding/fetch-image-data-url";
export const imageFetchPublicApi = "/public/tenants/branding/fetch-image";
export const IngredientsApi = "/private/tenants/menus/ingredients/ingredient";
export const ingredientsSingleApi = "/public/tenants/menu/ingredients/get";
export const ingredientsSingleApiBySlug = "/public/tenants/menu/ingredients/get";
export const ingredientsSingleApiPrivate = '/private/tenants/menus/ingredients/get'
export const invoiceListAllFiles = "/private/tenants/file_extract/invoices/list_all";
export const itemSortApi = "/private/tenants/menus/sort";
export const listAllAddOnGroupApi = "/private/tenants/menus/addons_groups/list";
export const listAllAddOnsApi = "/private/tenants/menus/addons/all";
export const listAllIngredientsApi = "/private/tenants/menus/ingredients/all";
export const listFileManagement = "/private/tenants/file_management/list";
export const listPostsApi = "/private/tenants/posts/list";
export const mandatoryDayApi = "/private/tenants/availability/staff";
export const markAllAsReadNotificationsApi = "/private/tenants/notifications/mark_as_read";
export const markAsReadNotificationsApi = "/private/tenants/notifications/update";
export const menuCategoryManagementApi = "/private/tenants/menus/category";
export const menuCategoryStatusApi = "/private/tenants/menus/category/status";
export const menuCopyCategoryToMenu = "/private/tenants/menus/category/copy_to_menu";
export const menuCopyItemToCategoryMenu = "/private/tenants/menus/item/copy_to";
export const menuItemApi = "/private/tenants/menus/item";
export const menuItemDetailsApi = "/private/tenants/menus/item/details";
export const menuItemManagementApi = "/private/tenants/menus/category_with_items";
export const menuItemsApi = "/private/tenants/menus/items_without_category";
export const menuItemStatusApi = "/private/tenants/menus/status";
export const menuMoveCategoryMenu = "/private/tenants/menus/move/sub_category";
export const menuMoveItemCategory = "/private/tenants/menus/move/menu_item";
export const menuMoveItemDuplicate = "/private/tenants/menus/item/duplicate";
export const menuScheduleApi = "/private/tenants/menu_schedule/menu/set";
export const menuScheduleGetApi = "/private/tenants/menu_schedule/menu/get";
export const newsAndEventsApi = "/private/tenants/posts/id";
export const newsAndEventsSlugApi = "/public/tenants/posts/slug";
export const newsAndEventsStatusApi = "/private/tenants/posts/status";
export const newsApi = "/private/tenants/posts/news";
export const newsListGuestViewApi = "/public/tenants/posts/list/news";
export const notificationsListApi = "/private/tenants/notifications/list";
export const notificationUnreadListApi = "/private/tenants/notifications/fetch/unread";
export const permissionListApi = "/private/tenants/permission_group/modules";
export const permissionPublicApi = "/public/tenants/general/p/modules";
export const permissionRoleListApi = "/private/tenants/permission_group/permission";
export const publicItemDetails = "/public/tenants/menu/list/get";
export const publicItemDetailsBySlug = "/public/tenants/menu/get";
export const publicMagicLink = "/public/tenant_auth/magic_link";
export const publicMenuList = "/public/tenants/menu/list";
export const publicSurveyQuestionsApi = "/public/tenants/survey/active/list";
export const publicSurveyQuestionSlugApi = "/public/tenants/survey/get/slug";
export const publicSurveySubmitApi = "/public/tenants/survey/active/save";
export const publicTenantsListApi = "/public/tenants/users/list";
export const publicUserTrackingApi = "/public/tenants/guests/visit";
export const qrCodeApi = "/private/tenants/menus/globals/set_qrcode";
export const qrCodeLabelApi = "/private/tenants/users/globals/set_qrcode_label";
export const resendPasswordEmailApi = "/private/tenants/users/resend_password_email";
export const resendPasswordSmsApi = '/private/tenants/users/resend_password_sms'
export const resetPasswordApi = "/public/tenant_auth/update_password";
export const saveFileUploadToDbApi = "/private/tenants/file_management/file_save";
export const setLogoApi = "/private/tenants/branding/set-logo";
export const setMenuRestTimeInMinutesApi = "/private/tenants/menus/globals/set_menu_reset_time";
export const setPasswordApi = "/public/tenant_auth/set_password";
export const shiftConfigApi = "/private/tenants/shifts";
export const staffAvailability = "/private/tenants/availability/staff";
export const staffingRequirementsApi = "/private/tenants/staffing_requirements/global";
export const staffsApi = "/private/tenants/users/staffs";
export const surveyDefaultApi = "/private/tenants/survey/sets/status/default";
export const surveyManagementApi = "/private/tenants/survey/sets";
export const surveyQuestionSetCloneApi = "/private/tenants/survey/sets/duplicate";
export const surveyQuestionSetStatusApi = "/private/tenants/survey/sets/status/active";
export const surveyReportsApi = "/private/tenants/survey/reports/list";
export const surveyReportsExportApi = "/private/tenants/survey/reports/generate_file";
export const tenantInfoApi = "/public/tenants/general/info";
export const timeOffApi = "/private/tenants/time_off";
export const toggleCardPriceApi = "/private/tenants/menus/globals/toggle_card_price";
export const toggleSuggestedCardPriceApi = "/private/tenants/menus/globals/toggle_suggested_card_price";
export const updateAboutUsDataApi = "/private/tenants/branding/about_us";
export const updateDateSettingsApi = "/private/tenants/branding/date_time_settings";
export const updateFestivalThemeApi = "/private/tenants/branding/festive_theme";
export const userGlobalConfigApi = "/private/tenants/users/globals";
export const userInfoApi = "/private/tenants/users/check_username";
export const usernameOnlyLogin = "/public/auth/pos_only_login";
export const userRolesApi = "/private/tenants/job_roles/item";
export const usersApi = "/private/tenants/users/list";
export const usersMaskedData = "/public/tenants/guests/masked";
export const userStatusApi = "/private/tenants/users/status";
export const verifyMagicLinkApi = "/public/tenant_auth/verify_magic_link";
export const welcomeConfigPublicApi = "/public/tenants/branding/welcome_page";
export const welcomeScreenConfigApi = "/private/tenants/branding/welcome_page";
export const scheduleApi = "/private/tenants/schedule";
export const getTenantUserApi = "/private/tenants/users/organization/check"
export const cloneUserApi = "/private/tenants/users/organization/tenant/clone";
export const areaListApi = "/private/tenants/job_roles_department/item";
export const shiftSwapAddApi = "/private/tenants/shift_swap/item";
export const shiftSwapUpdateApi = "/private/tenants/shift_swap/status";
export const shiftSwapListApi = "/private/tenants/shift_swap/manage/requests"
export const shiftSwapTakeApi = "/private/tenants/shift_swap/pool_request";
export const helpApi = "/public/help/faq/";