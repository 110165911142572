import { useEffect, useState } from 'react';
import { BUSINESS_CLOSE, HOLIDAY, MANDATORY, MANDATORY_POPUP_CONTENT, NO_TITLE } from '../../constants/strings';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { DayOfWeek } from '../../constants/staticTypes';
import { generateWeekdayDates, Weekday, days, getDateOfWeekday, TransformedData, transformData, PublishedSchedule, isDateInRange, PublishedScheduleExtended } from '../../helpers/workTimeScheduleHelper';
import { getFirstThreeLetter } from '../../helpers/utils';
import { JTranslation } from '../../helpers/jTranslate';
import dayjs from 'dayjs';
import Loader from '../loader/Loader';
import MlxPopover from '../common/MlxPopover';
import noPublishedSchedule from '../../assets/images/no_published_schedule.svg';

type Props = {
    selectedWeek: dayjs.Dayjs
    scheduleData: PublishedSchedule[]
    isFetching: boolean
}

const StaffSchedule = ({ selectedWeek, scheduleData, isFetching }: Props) => {
    const [weekdayDates, setWeekdayDates] = useState<Weekday[]>()
    const [transformedData, setTransformedData] = useState<TransformedData[] | []>([])

    useEffect(() => {
        if (scheduleData) {
            const data = transformData(scheduleData as unknown as PublishedScheduleExtended[])
            setTransformedData(data)
        }
    }, [scheduleData])

    useEffect(() => {
        if (selectedWeek) {
            const dates = generateWeekdayDates(selectedWeek)
            setWeekdayDates(dates)
        }
    }, [selectedWeek])

    useEffect(() => {
        const tdElements = document.querySelectorAll('td')

        tdElements.forEach((td) => {
            const div = td.querySelector('.staff-schedule')
            if (div) {
                td.classList.add('staff-schedule-card')
            }
        })
    }, [transformedData, weekdayDates])



    const headerTemplate = ({ day, rowData }: { day: DayOfWeek; rowData: TransformedData[] }) => {
        if (!rowData.length) return

        const { holidays, mandatoryDays } = rowData[0]

        const checkHoliday = () => {
            const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
            const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
            return isHolidays
        };

        const getHolidayTitle = () => {
            const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
            const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
            return holiday?.title ?? NO_TITLE
        };

        const getIsOpenForBusiness = () => {
            const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
            const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
            return holiday ? holiday.isOpenForBusiness : false
        };

        const checkMandatoryDays = () => {
            const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
            const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
            return isMandatory
        };

        return (
            <div className={`scheduler-header ${checkHoliday() && 'holiday-header'} ${checkMandatoryDays() && 'mandatory-day'}`}>
                <div className='mb-1 text-center text-nowrap fw-normal text-muted w-100'>
                    <div className="d-flex align-center justify-content-center position-relative">
                        {getFirstThreeLetter(day)}
                        {checkMandatoryDays()
                            ? (<span className="help-main ms-3 position-absolute end-0">
                                <MlxPopover data={{ title: MANDATORY, body: MANDATORY_POPUP_CONTENT }} iconName="ri-calendar-check-line" />
                            </span>)
                            : checkHoliday()
                                ? (<span className="help-main ms-3 position-absolute end-0">
                                    <MlxPopover data={{ title: getIsOpenForBusiness() ? HOLIDAY : BUSINESS_CLOSE, body: getHolidayTitle() }} iconName="ri-calendar-close-line" />
                                </span>)
                                : ""
                        }
                    </div>
                    <div className="mb-2 text-center text-nowrap">{getDateOfWeekday({ selectedWeek, day: day })}</div>
                </div>
            </div>
        )
    }

    const cellBodyTemplate = ({ rowData, weekDay }: { rowData: any, weekDay: DayOfWeek }) => {
        const { assignedShifts } = rowData
        return assignedShifts.map((assignedShifts: any, index: number) => {
            const { scheduleDate } = assignedShifts
            const currentCellDate = getDateOfWeekday({ day: weekDay, selectedWeek, format: 'YYYY-MM-DD' })
            if (scheduleDate === currentCellDate) {
                const { startTime, endTime, role, isBd, isClose } = assignedShifts
                return (
                    <div className="staff-schedule" key={index}>
                        {isClose ? <div className="time">{`${startTime} - "Close"`}</div> : ""}
                        {isBd ? <div className="time">{`${startTime} - "V"`}</div> : ""}
                        {!isBd && !isClose ? <div className="time">{`${startTime} - ${endTime}`}</div> : ''}
                        <div className="role">{<JTranslation text={role} />}</div>
                    </div>
                )
            }
        })

    }

    if (isFetching) {
        return <Loader />
    }

    if (!scheduleData.length) {
        return (
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                <div className="flex-grow-1 align-items-center justify-content-center p-3 text-center mt-5">
                    <img src={noPublishedSchedule} alt='no published schedule' style={{ textAlign: 'center' }} />
                    <h5 className="mt-2 mb-3">
                        <JTranslation typeCase="none" text={"No schedule assigned for the selected week."} />
                    </h5>
                </div>
            </div>
        )
    }

    return (
        <div className="card">
            <DataTable
                value={transformedData}
                scrollable
                scrollHeight="flex"
                responsiveLayout="scroll"
                className="work-time-schedule-table my-schedule"
            >
                {/* <Column field="time" className='text-nowrap' header="Reservation" style={{ width: '12.5%' }}  /> */}
                {days.map((day) => (
                    <Column
                        key={day}
                        header={(data) => headerTemplate({ day, rowData: data.props.value })}
                        body={(data) => cellBodyTemplate({ weekDay: day, rowData: data })}
                        style={{ width: '12.5%' }}
                        headerClassName="workTimeScheduleHeader"
                    />
                ))}
            </DataTable>
        </div>
    )
}

export default StaffSchedule;
