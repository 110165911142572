import { useContext, useEffect, useState } from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import { useWindowSize } from 'react-use'
import MyAvailability from '../../components/availability/MyAvailability'
import MlxPopover from '../../components/common/MlxPopover'
import { AVAILABILITY, AVAILABILITY_HELP } from '../../constants/strings'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import withSidebar from '../../hoc/withSidebar'
import { checkPermission } from '../../helpers/utils'
import { useQueryClient } from 'react-query'
import { PermissionKeys, SubPermissionKeys } from '../../constants/constants'

function Availability() {
	const {width: screenWidth} = useWindowSize()
	const queryClient = useQueryClient()

	// STATE VARIABLE
	const [activeIndex, setActiveIndex] = useState(0)

	// CONTEXT VARIABLE
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	const [translationText, setTranslatedText] = useState<{
		editAvailability: string,
	}>({ 
		editAvailability: "Edit Regular Availability",
	});

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: "Edit Regular Availability", typeCase: 'pascal', translationContext }),
				])
				
				setTranslatedText({
					editAvailability: translations[0] ?? "Edit Regular Availability",
				})
			} catch {
				setTranslatedText({
					editAvailability: "Edit Regular Availability",
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	type BadgeSeverityType = 'success' | 'info' | 'warning' | null | undefined
	const getBadgeStatus = (status: string): BadgeSeverityType => {
		switch (status) {
			case 'PENDING':
				return 'warning'
			case 'APPROVED':
				return 'success'
			default:
				return 'info'
		}
	}

	return (
		<div className="col-md-12 col-lg-12 mb-3 h-100 ">
			<div className="page-title">
				<h5 className="d-flex">
					<JTranslation typeCase="pascal" text={AVAILABILITY} />
					<MlxPopover data={{title:AVAILABILITY, body:AVAILABILITY_HELP}}/>
				</h5>
			</div>
			<div className="card h-100 ">
				<div className="card-body flex-grow-1 overflow-auto">
					<div className="survey-section h-100">
						<TabView
							className={`staff-schedule-panel mt-2 w-100 settings-tab ${screenWidth >= 800 ? 'hide-tab-arrow' : ''}`}
							activeIndex={activeIndex}
							onTabChange={(e) => setActiveIndex(e.index)}
							scrollable={true}
						>
							<TabPanel
								header={<JTranslation typeCase="pascal" text={'My Availability'} />}
							>
								<MyAvailability getBadgeStatus={getBadgeStatus} />
							</TabPanel>
							{checkPermission(
								queryClient,
								PermissionKeys.MANAGE_AVAILABILITY,
								SubPermissionKeys.MANAGE_REQUEST
							) && 
								<TabPanel
									header={<JTranslation typeCase="pascal" text={'Staff Availability'} />}
								>
									<MyAvailability getBadgeStatus={getBadgeStatus} parent='staffAvailability' />
								</TabPanel>
							}
						</TabView>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withSidebar(Availability)
